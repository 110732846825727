.main_container {
     width: 100%;
     flex: 1;

     :global {
          .nav {
               @media(max-width : 1399px) {
                    overflow: auto;
                    overflow-y: hidden;
               }

               .nav-link {
                    white-space: pre;

                    .pro-btn-link {
                         --pro-button-hover-bg: var(--pro-primary-hover-bg);
                         transition: none;

                         &.active {
                              color: var(--pro-primary);
                              border-top-left-radius: 6px;
                              border-top-right-radius: 7px;

                              &::after {
                                   background-color: var(--pro-primary);
                              }
                         }

                         &:not(.active):active {
                              background-color: var(--pro-button-hover-bg);
                         }
                    }
               }
          }

          .pro-pagination-wrapper {
               width: calc(100% - 385px);
          }
     }
}

.filter_btn {
     height: 100%;
     align-items: center;
}

:global(.pro-table td){
     .select_badge{
          padding: 5px 8px;
          color: var(--wac-badge-color);
          border: 1px solid var(--wac-badge-border-color);
          font-size: 14px;
          font-weight: 400;
          :global{
               .pro-input__input-container, .pro-input__single-value{
                    color: inherit;
                    line-height: 1.5;
               }
          }
     }
}