.table_wrapper {
  width: 100%;
  th {
    &:nth-child(1) {
      width: 50%;
    }
    &:nth-child(2) {
      width: 12%;
    }
    &:nth-child(3) {
      width: 38%;
    }
  }
}
