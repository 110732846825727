.fixed_position {
  position: fixed !important;
  top: 49px;
  z-index: 9;
  background: var(--pro-light);
  width: calc(100% - 88px);
  transition: width 0.25s ease-out, padding 0.25s ease-out;
  padding: 23px 0px 10px;
  & + div {
    padding-top: 32px;
  }
  :global(.pro-check-box) {
    margin-top: 6px;
  }
}

:global(.expanded) {
  .fixed_position {
    width: calc(100% - 280px);
  }
}

.root_modal {
    :global(.modal-dialog) {
      --pro-modal-width: 1000px;
      width: 100%;
    }
    :global(.modal-content) {
      padding: 20px;
      padding-bottom: 0;
    }
  }