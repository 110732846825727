.root {
  width: 100%;
  position: relative;
  height: 100%;
  .inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--pro-outline-border);
    border-radius: 6px;
    overflow: hidden;
    border-bottom: 3px solid #cfd3dc;
    transition: 0.3s all ease-in-out;
    height: 100%;
  }
  &:hover {
    .inner {
      border-bottom: 3px solid var(--pro-primary);
    }
  }
}
:global(.active) {
  .inner {
    border-bottom: 3px solid var(--pro-primary);
  }
}

.inner_heading {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  .icon {
    width: 30px;
    height: 30px;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--pro-outline-border);
    span {
      font-size: 20px;
      color: var(--pro-primary);
    }
    & + h6{
      flex: 1;
      margin-top: 4px;
    }
  }
}

.body {
  width: 100%;
  margin-top: auto;
}
