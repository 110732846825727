.toggle_wrapper{
    width: 100%;
    :global{
        label{
            font-size: 16px;
        }
        .pro-toggle-box{
            width: 40px;
            height: 22px;
            input[type=checkbox] ~ span{
                width: 17px;
                height: 17px;
                left: 3px;
            }
        }
    }
}