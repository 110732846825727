.overview_main {
  padding: 0 calc(1.5rem * 0.5);
  background-color: var(--pro-secondary);
  .overview_wrap_list {
    background-color: var(--pro-light);
    margin-top: 7px;
    overflow-x: hidden; //astrology table overlapping
    .multi_column_table {
      position: relative;
      &::after {
        position: absolute;
        content: "";
        right: 0;
        height: calc(100% - 37px);
        width: 1px;
        bottom: 4px;
        background-color: var(--pro-outline-border);
      }
      h6 + button:global(.pro-btn) {
        right: 30px;
        top: 0;
      }
    }
    &_item {
      table {
        tr {
          td {
            &:first-child:nth-last-child(2),
            &:first-child:nth-last-child(2) ~ td {
              width: 50%;
            }
            p {
              word-break: break-word;
            }
          }
        }
      }
    }
  }
  .spinner_wrap {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 999;
    // pointer-events: none;
    :global(.pro-spinner.lg) {
      --wac-spinner-size: 50px;
      --wac-spiner-width: 4px;
    }
  }
}
.horoscope_table_container {
  position: relative;
  .horoscope_table_wrap {
    padding-top: 2.813rem;
  }
  .editIcon {
    position: absolute;
    right: 10px;
    top: 0;
  }
}

.shimmer_wrapper {
  width: 100%;
  position: relative;
  background-color: var(--pro-light);
}

.edit_button {
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  width: 32px;
  height: 32px;
  background-color: var(--pro-light);
  z-index: 99;
  border-radius: 50%;
  transition: 0.3s all ease-in-out;
  &:hover {
    background-color: var(--pro-outline-border);
    color: var(--pro-primary);
  }
}
.p_rel {
  position: relative;
  padding-right: 30px;
}


.astroTab{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    height: 100%;
    gap: 30px;
     @media (max-width: 1366px) {
        gap: 10px;
     }
    .astroTabMain{
        width: 100%;
        max-width: 300px;
        height: 100%;
        @media (max-width: 1366px) {
            max-width: 200px;
            height: 200px;
        }
    }
}

.warning_box{
  color: var(--pro-light);
  background-color: var(--pro-warning-box-bg);
  border-radius: 6px;
  padding: 12px 1rem;
  gap: 0.45rem;
  p{
    font-size: 14px;
  }
  & + .warning_box{
    margin-top: 0.75rem;
  }
  &.danger{
    --pro-warning-box-bg : var(--pro-warning-box-danger);
  }
  &.warning{
    --pro-warning-box-bg : var(--pro-warning-box-warning);
  }
}


.horoscope_btn_wrap{
  min-width: 50%;
  >a{
    color: var(--pro-primary);
    text-decoration: underline;
    font-weight: 500;
    text-underline-offset: 2px;
  }  
}