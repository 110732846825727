.header {
  background-color: var(--pro-light);
  box-shadow: 0 0 4px 1px rgb(9 30 66 / 13%), 0 0 4px 0 rgb(9 30 66 / 8%);
  padding: 7px 0px;
}

:global(.sub-icon) {
  width: 24px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  opacity: 1;
  transition: .3s all ease;
}

:global(.expanded .sub-icon) {
  opacity: 0;
}
.MenuDrawer_container {
  :global(.nav-link .material-symbols-outlined) {
    max-width: 22px;
  }
  .type_2{
    padding-inline: 0 !important;
    height: calc(100vh - 56px);
        padding: 0px 16px;
    :global(.nav.nav-pills){
      padding-inline: 10px;
      transition: padding-inline 0.2s;
      overflow-y: auto !important;
      height: 100%;
      display: flex;
      flex-wrap: nowrap;
      padding-top: 20px;
      padding-bottom: 20px;
      span{
        display: flex;
        justify-content: center;
        flex-direction: column;
        div{
          width: 100%;
        }
      }
    }
    >div:not(:global(.nav.nav-pills)){
      padding: 16px 10px !important;
      margin-bottom: 0 !important;
      transition: all 0.2s;
      > * {
        opacity: 0;
        transition: all 0.25s;
      }
    }
  }
}
body{
  &:global(.expanded) {
  .MenuDrawer_container .type_2 {
    :global(.nav.nav-pills){
      padding-inline: 1rem;
    }
    >div:not(:global(.nav.nav-pills)){
      padding: 16px !important;
      > * {opacity: 1;}
    }
  }
 }
}
