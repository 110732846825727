.root{
    display: flex;
    justify-content: center;
    padding: 6.125rem 0;
    .image_inner{
        max-width: 4.063rem;
        margin: auto;
        margin-bottom: 1.25rem;
    }
    .title, .subtitle{
        opacity: 0.8;
    }
}