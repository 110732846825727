.root{
    width: 100%;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.root_items{
    width: 50%;
    flex: 1;
    display: flex;
}