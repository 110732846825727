.root {
     width: 100%;
     display: flex;
     flex-direction: column;

     .root_header {
          width: 100%;
          position: relative;
          padding-right: 30px;

          .root_Icon {
               position: absolute;
               right: 0;
               top: -5px;
          }

          &:empty {
               display: none;
          }
     }

     .root_body {
          width: 100%;
          position: relative;
     }
}

.btn_read_more {
     cursor: pointer;
}

.p_call_number {
     cursor: pointer;
}

.button_history {
     margin-left: 2px;
}

.value_box {
     display: flex;
     align-items: center;
     white-space: nowrap;
}