@import "~@wac-ui-dashboard/wac_component_library/scss/variables.scss";
@import "~@wac-ui-dashboard/wac_component_library/scss/bootstrap.scss";
@import "~@wac-ui-dashboard/wac_component_library/scss/common.scss";
@import "~react-date-range/dist/styles.css"; // main css file
@import "~react-date-range/dist/theme/default.css";
@import "./config";

:root,
[data-theme*="light"] {
  @include light-theme;
  --pro-primary-hover-bg: #{$pro-primary-hover-bg-light};
  --pro-table-bg: #{$pro-table-bg-light};
  --pro-table-bg-border: #{$pro-table-bg-border-light};
  --pro-warning-box-danger: #{$pro-warning-box-danger-light};
  --pro-warning-box-warning: #{$pro-warning-box-warning-light};
  --pro-toggle-bg: #{$pro-toggle-bg-light};
}

[data-theme*="dark"] {
  @include dark-theme;
  --pro-primary-hover-bg: #{$pro-primary-hover-bg-dark};
  --pro-table-bg: #{$pro-table-bg-dark};
  --pro-table-bg-border: #{$pro-table-bg-border-dark};
  --pro-warning-box-danger: #{$pro-warning-box-danger-dark};
  --pro-warning-box-warning: #{$pro-warning-box-warning-dark};
  --pro-toggle-bg: #{$pro-toggle-bg-dark};
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fafafa;
  }

  &::-webkit-scrollbar-thumb {
    // background-color: var(--pro-input-focus-shadow);
    background-color: #e2e2e2;
  }

  scrollbar-width: thin;
}

.modal,
.pro-scroll-lg {
  *::-webkit-scrollbar {
    width: 10px;
    height: 15px;
  }
}

html {
  font-size: 16px;
}

body {
  font-size: 0.875rem;
  line-height: $primary-line-height;
}

.wrapper {
  width: max-content;
  height: calc(100vh - 56px);
  position: sticky;
  top: 56px;

  >div {
    height: 100%;
  }
}

.pro-pagination {
  z-index: 9999;
}

.wrapper-table {
  flex: 1;
  width: calc(100% - 260px);
  overflow: hidden;
  position: relative;

  .pro-pagination {
    display: flex;
    justify-content: center;
  }

  .pro-input .pro-input__single-value {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1;
  }

  .pro-btn-group {
    &:empty {
      display: none;
    }

    .pro-btn-secondary {
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }

      span {
        margin-right: 8px;
      }
    }
  }
}

.wrapper-profile-table {
  .pro-pagination {
    display: flex;
    justify-content: center;
  }

  .pro-input .pro-input__single-value {
    padding-top: 0;
    padding-bottom: 0;
  }

  .pro-btn-group {
    &:empty {
      display: none;
    }

    .pro-btn-secondary {
      margin-left: 10px;
      display: flex;
      align-items: center;

      &:first-child {
        margin-left: 0;
      }

      span {
        margin-right: 8px;
      }
    }
  }
}

.pro-pagination {
  .pro-input .pro-input__single-value {
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

.wrapper-tables {
  width: 100%;
  overflow: hidden;
}

.pt-custom {
  padding-top: size(56);
}

.react-transform-component,
.react-transform-wrapper {
  width: 100% !important;
}

.shimmer {
  background: #f6f7f8;
  background-image: linear-gradient(to right,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 100%;
  display: inline-block;
  position: relative;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: shimmer;
  -webkit-animation-timing-function: linear;

  &.letter {
    width: 100%;
    height: 15px;

    &.sub {
      width: 90%;
    }

    &.para {
      height: 6px;
    }
  }

  &.img {
    padding-bottom: 100%;
    width: 100%;
  }
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.light_primary_bg {
  background-color: var(--pro-table-bg) !important;

  .shimmer {
    background: #f9e5df;
    background-size: 800px 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(to right,
        #f3e5e1 0%,
        #fbf0ed 20%,
        #fde8e2 40%,
        #f9e5df 100%);
  }
}

.input-wrap.sm {
  .input-drag-box {
    height: 40px;
  }
}

//increase font
.font-inc {
  font-size: 17px;
  letter-spacing: -0.15px;
  position: relative;

  .pro-font-sm {
    font-size: 17px;
    letter-spacing: -0.15px;
  }

  .no-border tr,
  .no-border td {
    padding: 3px !important;
  }
}

.error-message {
  font-size: 14px;
  line-height: 1;
  margin-top: 10px;
  color: var(--pro-danger);
  width: 100%;
  padding: 15px;
  display: block;
  background-color: var(--pro-input-hover-bg);
}

.fill-width .pro-input {
  min-width: 100%;
}

.input-drag-box.top {
  pointer-events: all;
  z-index: 99;
  position: relative;
  display: flex;
  align-items: center;

  span {
    display: block;
    padding-left: 7px;
    padding-top: 4px;
    cursor: pointer;
  }
}

.pro-pagination-wrapper {
  padding: 8px 0px;
  border-top: 1px solid var(--pro-outline-border);
  z-index: 99;
}

.hide-icon {
  position: absolute;
  right: 0;
  top: 24px;
  border: none;
  background-color: transparent;
  width: 41px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hide-icon-input {
  padding-right: 40px;
}

.p-re {
  position: relative;

  .input-wrap {
    position: relative;
  }

  .hide-icon {
    right: 12px;
  }
}

.rm-border {
  div {
    border: none !important;
    border-radius: 0px !important;
  }
}

.scoll-top {
  position: fixed;
  bottom: 20px;
  right: 40px;
  width: 40px;
  height: 40px;
  border: 1px solid var(--pro-primary);
  background-color: var(--pro-light);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  z-index: 999;
  transition: 0.3s all ease-in-out;

  span {
    font-size: 25px !important;
    color: var(--pro-primary);
    transition: 0.3s all ease-in-out;
  }

  &:hover {
    background-color: var(--pro-primary);

    span {
      color: var(--pro-light) !important;
    }
  }
}

.pro-input__option--is-focused:active,
.pro-input__option--is-focused {
  background-color: var(--pro-outline-hover) !important;
}

.pro-input__option--is-selected,
.pro-input__option--is-selected:active {
  background-color: var(--pro-primary) !important;
}

.pro-input .pro-input__multi-value .pro-input__multi-value__label {
  line-height: 1;
  font-size: 0.675rem;
}

.pro-input .pro-input__single-value {
  line-height: 1;
}

.pro-input.lg {
  min-height: 40px;
}

.react-time-picker__inputGroup__input:focus-visible {
  outline: none;
  border: none;
  box-shadow: none;
}

.profile_layout {
  .scoll-top {
    right: 230px;
  }
}

.close-right-drawer {
  .scoll-top {
    right: 98px;
  }
}

.pro-pnt {
  color: var(--pro-primary);
  font-weight: 400; // no variables for fw-semibold
  cursor: pointer;
}

.modal-dialog {
  .pro-pagination-wrapper {
    position: absolute;
    left: 0;
    width: 100%;
  }
}

.web-id {
  color: var(--pro-primary);
  font-weight: 500;

  span {
    color: var(--pro-dark);
    font-weight: 400;
  }
}

.erro-web-id {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: -10px;
  margin-bottom: 5px;

  >span {
    display: block;
    width: 100%;
    position: relative;
    font-size: 12px;
  }

  .err {
    color: var(--pro-danger);
  }
}

.pro-btn.pro-btn-primary.whatsapp {
  --pro-button-bg: #43d854;
  --pro-button-hover-bg: #39bb48;
  --pro-button-active-bg: #4fee62;

  span {
    display: flex;
    align-items: center;
  }

  span+svg {
    margin-left: 8px;
  }
}

.pro-btn {
  align-items: center;
}

.app-loading {
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;

  &::after {
    width: 30px;
    height: 30px;
    border: 2px solid var(--pro-primary);
    border-radius: 50%;
    overflow: hidden;
    border-bottom-color: transparent;
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: rotation 0.6s infinite linear;
  }

  &.fill {
    position: fixed;
  }
}

@keyframes rotation {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}

.page-break {
  page-break-before: always;
}

.pro-input .pro-input__menu {
  z-index: 100;
}


.single-line {
  white-space: pre;
}

.pro-input:focus-visible,
.pro-input.active,
.pro-input:active {
  box-shadow: 0px 0px 0px 1.5px var(--pro-input-focus-shadow);
}

.form-check.form-switch {
  min-height: 22px;

  .form-check-input {
    width: 1.75rem;
    height: 1rem;
    border: 0 !important;
    background-size: 17px;
    background-color: var(--pro-toggle-bg);
    border-color: var(--pro-toggle-bg);
    margin-top: 3px;
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e');

    &:checked {
      background-color: var(--pro-primary);
      border-color: var(--pro-primary);
      background-position: 11px center;
    }
  }
}

.input-country-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;

  .input-main-wrap {
    position: relative;
    width: 100%;

    .code-wrap {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.34375rem 0.4375rem;
      font-size: 0.85rem;
      max-width: 84px;
      cursor: pointer;
      user-select: none;

      &::after {
        position: absolute;
        content: "";
        width: 1px;
        top: 5px;
        bottom: 5px;
        right: 0;
        background-color: var(--pro-border-color);
      }

      .country-select {
        .pro-input__control {
          background-color: inherit;

          .pro-input__single-value {
            color: var(--pro-dark);
          }
        }

        .pro-input__menu {
          background-color: var(--pro-light);
        }
      }

      .pro-input__control {
        border: 0;
        box-shadow: none !important;
      }

      .pro-input__value-container {
        padding: 0;
      }

      .pro-input__indicator-separator {
        display: none;
      }

      .pro-input__placeholder {
        font-size: 14px;
        color: var(--pro-dark);
      }

      .pro-input__indicator {
        padding: 4px;
      }

      .pro-input__menu-list {
        max-height: 250px;
      }

      .pro-input__menu {
        width: max-content;
        max-width: 300px;
      }
    }

    .pro-input {
      padding-left: 6rem;
    }

    &.verify-btn-wrap .pro-input {
      padding-right: 5rem;
    }
  }

  .error-text {
    font-size: 12px;
    line-height: $secondary-line-height;
    margin-top: 10px;
    color: var(--pro-danger);
  }
}

.pro-badge.badge-warning {
  --wac-badge-color: var(--pro-dark);
  --wac-badge-bg: var(--pro-warning-border-subtle);
  --wac-badge-border-color: var(--pro-warning-border-subtle);
}
.pro-badge.badge-warning-outline {
  --wac-badge-color: #ffb108;
  --wac-badge-border-color: #ffb108;
}

.time-picker.rs-picker-popup {
  z-index: 999;
}

.pro-toggle {
  display: flex;
  align-items: center;

  .pro-toggle-box {
    position: relative;
    width: 28px;
    height: 16px;

    input[type="checkbox"] {
      width: 100%;
      height: 100%;
      appearance: none;
      background: var(--pro-toggle-bg);
      border-radius: 100px;
      cursor: pointer;
      transition: 0.4s;
    }

    input:checked[type="checkbox"] {
      background: var(--pro-primary);
    }

    input[type="checkbox"]~span {
      position: absolute;
      content: "";
      width: 13px;
      height: 13px;
      top: 50%;
      left: 1px;
      background: var(--pro-light);
      border-radius: 50%;
      transition: 0.3s;
      pointer-events: none;
      transform: translateY(-50%);
    }

    input:checked[type="checkbox"]~span {
      left: 49%;
    }

    &+label {
      margin-left: 12px;
      color: var(--pro-input-text);
      font-size: 13px;
      margin-bottom: 0;
    }
  }
}