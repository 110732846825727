.shimmer{
    width: 100%;
    position: relative;
}

.role_text{
    width: 100%;
    max-width: 150px;
}
.icon{
    width: 20px;
    height: 20px;
    margin-right: 12px;
}