.automatch_shimmer{
    left: 0;
    top: 0;
    width: 100%;
    z-index: 99;
    pointer-events: none;
    border-radius: 6px;
    border: 1px solid var(--pro-outline-border);
    // table{
    //     td{
    //         width: 50%;
    //     }
    // }
}
.shimmer_image_wrapper{
    width: 100%;
    max-width: 11.8115rem;
}
.shimmer_table_wrapper{
    width: 100%;
    max-width: calc(100% - 11.8115rem);
}