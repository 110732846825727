.image_wrapper {
  position: relative;

  img {
    width: 100%;
  }
}

.document_wrapper {
  position: relative;

  .image_wrapper_main {
    border: 1px solid var(--pro-outline-border);
    padding: 16px 10px;
    border-radius: 4px;

    .name {
      max-width: 80%;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: var(--pro-primary);
    }
  }

  .document_img {
    position: relative;
    max-width: 150px;
    margin: auto;
  }

  img {
    width: 100%;
  }
}




.delet_btn {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  bottom: 15px;
  right: 15px;
  background-color: var(--pro-light);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.301);
  z-index: 9;
  cursor: pointer;
  transition: 0.3s all ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
}

.image_wrapper_main {
  position: relative;
  overflow: hidden;

  >div:first-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      filter: blur(5px);
    }
  }

  >div:last-child {
    position: relative;
    z-index: 1;

    img {
      object-fit: contain;
    }
  }
}