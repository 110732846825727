.root {
  width: 539px;
  position: relative;
  border-radius: 6px;
  border: 1px solid var(--pro-outline-border);
  transition: 0.3s all ease-in-out;

  &:global(.light_primary_bg) {
    border: 1px solid var(--pro-table-bg-border);
  }

  &:hover {
    border: 1px solid var(--pro-primary);
  }

  cursor: pointer;

  :global(.pro-check-box .pro-check) {
    border: none;
    // background-position: 0px 0px !important;
    box-shadow: 1px 1px 11px rgba(0, 0, 0, 0.161);
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center !important;
    background-size: 30px;
  }

  :global(.pro-check-box .pro-check) {
    opacity: 0;
    transition: 0.3s all ease-in-out;
  }

  :global(.pro-check-box .pro-check:checked) {
    opacity: 1;
  }

  &:hover {
    :global(.pro-check-box .pro-check) {
      opacity: 1;
    }
  }
}

.root_inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  :global(.pro-table) {

    tr,
    td,
    th {
      padding-top: 3px;
      padding-bottom: 3px;
      padding-right: 5px;

      p {
        word-break: break-all;
      }
    }

    td {
      &:nth-child(1) {
        width: 40%;
      }
    }
  }
}

.image_wrapper {
  width: 100%;
  max-width: 11.8115rem;

  @media (max-width: 1699px) {
    max-width: 130px;
  }

  @media (max-width: 1599px) {
    max-width: 100%;
    display: flex;
    margin-bottom: 20px;
  }

  :global(.checkbox) {
    position: absolute;
    z-index: 3;
    left: 28px;
    top: 30px;
    width: unset;
  }

  .image_inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 6px;
    overflow: hidden;

    @media (max-width: 1599px) {
      width: 130px;
      margin-right: 20px;
    }

    div {
      &:nth-child(1) {
        z-index: 2;

        img {
          object-fit: contain;
        }
      }

      &:nth-child(2) {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        img {
          filter: blur(3px);
        }
      }
    }
  }

  :global(.pro-btn.pro-btn-outline) {
    --pro-outline-border: var(--pro-primary);
    margin-top: 10px;
  }
}

.table_wrapper {
  width: 100%;
  max-width: calc(100% - 11.8115rem);

  @media (max-width: 1699px) {
    max-width: calc(100% - 130px);
  }

  @media (max-width: 1599px) {
    max-width: 100%;
    padding-left: 10px !important;
  }

  table {
    width: 100%;
    position: relative;
    margin-top: -6px;

    tr {
      td {
        padding: 0.148rem;

        &:last-child {
          padding-left: 5px !important;
        }
      }
    }
  }
}

.user_action {
  width: 100%;
  position: relative;
}

.match_badge {
  position: absolute;
  left: 5px;
  bottom: 10px;
  z-index: 3;
  width: 100px;
  pointer-events: none;

  img {
    width: 100%;
  }
}

.match_badge_static {
  max-width: 90px;
  margin-top: 15px;
  display: inline-block;

  img {
    width: 100%;
  }
}

.btn {
  @media (max-width: 1599px) {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}

.badge_wrapper {
  padding-left: 0px;
  padding-top: 10px;

  @media (max-width: 1599px) {
    padding-left: 10px;
    padding-top: 0;
  }
}