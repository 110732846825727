.table_wrapper{
    width: 100%;
    :global(.image-root){
        width: 70px;
        border-radius: 6px;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
    }
    span{
        display: block;
    }
    :global(.pro-table){
        tr > *:first-child{
            width: 120px;
        }
    }
}