 .main_wrapper {
   width: 100% !important;
   position: relative !important;
   height: 100%;

   .btn_group:empty {
     display: none;
   }
 }

 .automatch_shimmer {
   position: absolute;
   top: 0;
   left: 0;
   z-index: 99;
   width: 100%;
 }

 :global {
   .col-xxl-6 {
     position: relative;
   }
 }

 .user_table {
   width: 100%;
   position: relative;
   display: flex;
   flex-wrap: wrap;
   border: 1px solid var(--pro-outline-border);
   border-radius: 6px;
   margin-bottom: 15px;

   .user_table_items {
     width: 100%;
     max-width: 50%;
     position: relative;

     &:first-child {
       &::after {
         content: "";
         height: calc(100% - 10px);
         margin: auto;
         border-right: 1px solid var(--pro-outline-border);
         position: absolute;
         bottom: 0;
         right: 10px;
         width: 1px;
       }
     }
   }
 }

 .data_wrapper {
   width: 100%;
   display: flex;
   flex-wrap: wrap;
   position: relative;
   padding: 15px;
   background-color: var(--pro-table-bg);

   &::after {
     content: "";
     position: absolute;
     width: calc(100% - 30px);
     margin: auto;
     border-top: 1px solid var(--pro-outline-border);
     position: absolute;
     top: 0;
   }

   .heading {
     width: 100%;
     position: relative;
     margin-bottom: 15px;
     color: var(--pro-dark);
     font-size: 18px;
     font-weight: 500;
   }

   .data_item {
     display: flex;
     position: relative;
     padding-right: 30px;
     margin: 6px 0px;

     .data_key {
       position: relative;
       font-size: 16px;
       color: var(--pro-dark);
       padding-right: 15px;
     }

     .data_value {
       position: relative;
       font-size: 16px;
       color: var(--pro-dark);
       padding-left: 15px;
     }
   }
 }



 .match_badge {
   position: absolute;
   right: 16px;
   top: 16px;
   z-index: 3;
   width: 100px;
   pointer-events: none;

   img {
     width: 100%;
   }
 }