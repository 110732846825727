.root_items {
  width: 100%;
  max-width: 140px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  &:last-child {
    margin-right: 0;
  }
}

.profile_create {
  padding-bottom: 60px;
  > div {
    &:first-child {
      & + div {
        padding-left: 5px !important;
        padding-right: 5px !important;
      }
    }
  }

  h6 {
    color: var(--pro-primary);
    font-size: 1.25rem;
    margin-bottom: 1rem !important;
    margin-top: 0.3rem !important;
  }

  :global(.btn-fixed-btm) {
    position: fixed;
    bottom: 0;
    right: 0;
    background: #fff;
    padding: 10px 10px;
    width: 100%;
    max-width: calc(100% - 48px);
    box-shadow: 9px 4px 20px 0px #0000001a;
    transition: 0.3s all ease;
    :global(.pro-btn) {
      width: 140px;
      font-size: 1rem;
    }
  }
}

:global(.expanded) {
  .profile_create {
    :global(.btn-fixed-btm) {
      max-width: calc(100% - 240px);
      z-index: 2;
    }
  }
}
.web_id {
  color: var(--pro-primary);
  font-weight: 500;
  span {
    color: var(--pro-dark);
    font-weight: 400;
  }
}
