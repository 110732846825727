.root{
    width: 100%;
    position: relative;
    & ~ .root{
        padding-top: 2.1875rem;
    }
    ul{
        padding: 0px;
        margin: 0px;
        position: relative;
        li{
            list-style: none;
            appearance: none;
            width: 100%;
            button{
                display: flex;
                align-items: center;
                span{
                    white-space: pre;
                    &:last-child{
                        padding-left: .3889rem;
                    }
                }
            }
        }
    }
}


.items{
    width: 100%;
    :global{
        .pro-btn-secondary{
            border: 1px solid var(--pro-outline-border);
        }
    }
}

.heading{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}