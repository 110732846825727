.root{
    :global(.pro-table){
        td p:last-child{
            margin-bottom: 0;
        }
    }
}

.root_modal {
    :global(.modal-dialog) {
      --pro-modal-width: 1000px;
      width: 100%;
    }
    :global(.modal-content) {
      padding: 20px;
      padding-bottom: 0;
    }
  }
  