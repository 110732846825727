.timePicker {
  :global {
    .react-time-picker__wrapper {
      border: none;

      >* {
        padding: 0 !important;
        display: flex;
        align-items: center;
      }

      .react-time-picker__inputGroup__divider {
        padding: 0 0;
      }
    }
  }
}

.heading {
  width: 100%;
  position: relative;
}