.preloader {
     display: flex;


     span {
          width: 8px;
          height: 8px;
          background: #950053;
          display: block;
          margin: 0 5px;
          border-radius: 50%;
          transition: .2s ease-in-out;
          opacity: 0;

          &:nth-child(1) {
               animation: dots 1s linear infinite;
               // animation-delay: 0;
          }

          &:nth-child(2) {
               animation: dots 1s linear infinite;
               animation-delay: .1s;
          }

          &:nth-child(3) {
               animation: dots 1s linear infinite;
               animation-delay: .2s;
          }
     }

}

@keyframes dots {
     0% {
          transform: scale(0.5);
          opacity: 0;
     }

     100% {
          transform: scale(1);
          opacity: 1;
     }
}