
    .fixed_position{
        position: fixed !important;
        top: 49px;
        z-index: 9;
        background: var(--pro-light);
        width: calc(100% - 88px);
        transition: width .25s ease-out,padding .25s ease-out;
        padding: 23px 0px 10px;
        & + div{
            padding-top: 32px;
        }
        :global(.pro-check-box){
            margin-top: 6px;
        }
    }


:global(.expanded){
    .fixed_position{
        width: calc(100% - 280px);
    }
}