.root{
    width: 100%;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    :global(.input-wrap)
    {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        & > div{
            height: 42px;
            width: 100%;
            max-width: calc(100% - 115px);
            & + button{
                width: 100%;
                max-width: 105px;
                white-space: nowrap;
            }
        }

    }
}