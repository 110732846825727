$pro-input-focus-shadow-light : #950053a6;
$pro-input-focus-shadow-dark : #950053a6;





// Light theme color variables
$pro-primary-light: #950053;
$pro-primary-text-light: #ffffff;
$pro-primary-hover-light: #86004b;
$pro-primary-active-light: #a01a64;
$pro-secondary-light: #f4f5f7;
$pro-secondary-hover-light: #f0f1f3;
$pro-secondary-active-light: #ebecf0;
$pro-success-light: #117872;
$pro-danger-light: #b32318;
$pro-light-light: #fff;
$pro-dark-light: #000;
$pro-outline-light: #fff;
$pro-outline-hover-light: #f8f9fb;
$pro-outline-active-light: #f4f5f7;
$pro-outline-border-light: #dfe3eb;
$pro-disable-text-light: #a5adba;
$pro-disable-bg-light: #f5f6f7;
$pro-input-text-light: #4c4c4c;
$pro-input-hover-bg-light: #fafbfc;
$pro-input-focus-shadow-light: #68003a;
$pro-input-active-light: #7a869a;
$pro-avatar-remaining-bg-light: #dfe1e6;
$pro-nav-btn-link-hover-light: #ebebeb;
$pro-check-icon-color-light: #A11D2B;
$pro-primary-hover-bg-light: #e1e1e1;
$pro-table-bg-light :#e3319426;
$pro-table-bg-border-light :#F9E6E1;
$pro-warning-box-danger-light : #8A1521;
$pro-warning-box-warning-light : #cb960a;
$pro-toggle-bg-light: #D2D1D4;


// Dark theme color variables
$pro-primary-dark: #950053;
$pro-primary-text-dark: #ffffff;
$pro-primary-hover-dark: #86004b;
$pro-primary-active-dark: #a01a64;
$pro-secondary-dark: #4e4e4e;
$pro-secondary-hover-dark: #727272;
$pro-secondary-active-dark: #393939;
$pro-success-dark: #117872;
$pro-danger-dark: #b32318;
$pro-light-dark: #000;
$pro-dark-dark: #fff;
$pro-outline-dark: #000;
$pro-outline-hover-dark: #727272;
$pro-outline-active-dark: #393939;
$pro-outline-border-dark: #404040;
$pro-disable-text-dark: #a5adba;
$pro-disable-bg-dark: #f5f6f7;
$pro-input-text-dark: #ebeaea;
$pro-input-hover-bg-dark: #0a0a0a;
$pro-input-focus-shadow-dark: #68003a;
$pro-input-active-dark: #7a869a;
$pro-avatar-remaining-bg-dark: #dfe1e6;
$pro-nav-btn-link-hover-dark: #deebff;
$pro-check-icon-color-dark: #A11D2B;
$pro-primary-hover-bg-dark: #e1e1e1;
$pro-table-bg-dark :#303030;
$pro-table-bg-border-dark :#F9E6E1;
$pro-warning-box-danger-dark : #8A1521;
$pro-warning-box-warning-dark : #cb960a;
$pro-toggle-bg-dark: #D2D1D4;




.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
}