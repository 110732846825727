[hidden],
template {
     display: none
}

.rs-anim-fade {
     opacity: 0;
     pointer-events: none;
     -webkit-transition: opacity .15s linear;
     transition: opacity .15s linear
}

.rs-anim-fade.rs-anim-in {
     opacity: 1;
     pointer-events: unset
}

.rs-btn {
     -webkit-box-align: center;
     -ms-flex-align: center;
     -webkit-box-pack: center;
     -ms-flex-pack: center;
     align-items: center;
     background-color: #f7f7fa;
     background-color: var(--rs-btn-default-bg);
     border: none;
     border: var(--rs-btn-default-border, none);
     border-radius: 3px;
     color: var(--rs-btn-default-text);
     cursor: pointer;
     display: -webkit-inline-box;
     display: -ms-inline-flexbox;
     display: inline-flex;
     font-size: 14px;
     font-weight: 400;
     justify-content: center;
     line-height: 20px;
     margin-bottom: 0;
     overflow: hidden;
     padding: 8px 12px;
     position: relative;
     text-align: center;
     text-decoration: none;
     -webkit-transition: color .15s ease-out, background-color .15s ease-out;
     transition: color .15s ease-out, background-color .15s ease-out;
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
     vertical-align: middle;
     white-space: nowrap
}



.rs-btn-ghost.rs-btn {
     padding: 7px 11px
}

.rs-btn-icon.rs-btn {
     line-height: 16px;
     padding: 10px
}

.rs-btn-icon.rs-btn>.rs-icon {
     font-size: 16px
}

.rs-btn-icon-with-text.rs-btn {
     line-height: 20px
}

.rs-btn-icon-with-text.rs-btn>.rs-icon {
     height: 36px;
     padding: 10px;
     width: 36px
}

.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-left {
     padding: 8px 12px 8px 48px
}

.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-left>.rs-icon {
     border-right: none;
     border-right: var(--rs-btn-default-border, none);
     left: 0
}

.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-right {
     padding: 8px 48px 8px 12px
}

.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-right>.rs-icon {
     border-left: none;
     border-left: var(--rs-btn-default-border, none);
     right: 0
}

.rs-btn:focus-visible {
     outline: 3px solid rgb(from #3498ff rgb/25%);
     outline: 3px solid var(--rs-color-focus-ring)
}


.rs-btn:focus,
.rs-btn:hover {
     background-color: #e5e5ea;
     background-color: var(--rs-btn-default-hover-bg);
     color: var(--rs-btn-default-hover-text);
     text-decoration: none
}

.rs-btn.rs-btn-active,
.rs-btn:active {
     background-color: #d9d9d9;
     background-color: var(--rs-btn-default-active-bg);
     color: #272c36;
     color: var(--rs-btn-default-active-text)
}

.rs-btn.rs-btn-disabled,
.rs-btn:disabled {
     background-color: #f7f7fa;
     background-color: var(--rs-btn-default-disabled-bg);
     color: #c5c6c7;
     color: var(--rs-btn-default-disabled-text);
     cursor: not-allowed
}


@media not all and (min-resolution:0.001dpcm) {
     .rs-btn {
          -webkit-mask-image: -webkit-radial-gradient(#fff, #000)
     }
}

.rs-btn-start-icon {
     line-height: 0;
     margin-right: 5px
}

.rs-btn-end-icon {
     line-height: 0;
     margin-left: 5px
}

.rs-btn-primary {
     background-color: #3498ff;
     background-color: var(--pro-primary);
     border: none;
     color: #fff;
     color: #ffffff
}

.rs-btn-primary:focus,
.rs-btn-primary:hover {
     background-color: #2589f5;
     background-color: var(--pro-primary-hover);
     color: #fff;
     color: #ffffff
}

.rs-btn-primary.rs-btn-active,
.rs-btn-primary:active {
     background-color: #1675e0;
     background-color: var(--pro-primary-active);
     color: #fff;
     color: #ffffff
}

.rs-btn-primary.rs-btn-disabled,
.rs-btn-primary:disabled {
     background-color: #3498ff;
     background-color: var(--pro-primary);
     color: #fff;
     color: #ffffff;
     opacity: .3
}


.rs-btn-subtle {
     background-color: transparent;
     border: none;
     color: var(--rs-btn-subtle-text)
}

.rs-btn-subtle:focus,
.rs-btn-subtle:hover {
     background-color: #e5e5ea;
     background-color: var(--rs-btn-subtle-hover-bg);
     color: var(--rs-btn-subtle-hover-text)
}

.rs-btn-subtle.rs-btn-active,
.rs-btn-subtle:active {
     background-color: #e5e5ea;
     background-color: var(--rs-btn-subtle-active-bg);
     color: #272c36;
     color: var(--rs-btn-subtle-active-text)
}

.rs-btn-subtle.rs-btn-disabled,
.rs-btn-subtle:disabled {
     background: none;
     color: #c5c6c7;
     color: var(--rs-btn-subtle-disabled-text)
}



.rs-btn-link {
     background-color: transparent;
     border: none;
     color: #1675e0;
     color: var(--rs-btn-link-text)
}

.rs-btn-link:focus,
.rs-btn-link:hover {
     background-color: transparent;
     color: #0a5dc2;
     color: var(--rs-btn-link-hover-text);
     text-decoration: underline
}

.rs-btn-link.rs-btn-active,
.rs-btn-link:active {
     background-color: transparent;
     color: #004299;
     color: var(--rs-btn-link-active-text)
}

.rs-btn-link.rs-btn-disabled,
.rs-btn-link:disabled {
     background-color: transparent;
     color: #0a5dc2;
     color: var(--rs-btn-link-hover-text);
     opacity: .3;
     text-decoration: none
}

.rs-btn-ghost {
     background-color: transparent;
     border: 1px solid #1675e0;
     border: 1px solid var(--rs-btn-ghost-border);
     color: #1675e0;
     color: var(--rs-btn-ghost-text)
}

.rs-btn-ghost:focus,
.rs-btn-ghost:hover {
     background-color: transparent;
     border-color: #0a5dc2;
     border-color: var(--rs-btn-ghost-hover-border);
     -webkit-box-shadow: 0 0 0 1px #0a5dc2;
     -webkit-box-shadow: 0 0 0 1px var(--rs-btn-ghost-hover-border);
     box-shadow: 0 0 0 1px #0a5dc2;
     box-shadow: 0 0 0 1px var(--rs-btn-ghost-hover-border);
     color: #0a5dc2;
     color: var(--rs-btn-ghost-hover-text)
}

.rs-btn-ghost.rs-btn-active,
.rs-btn-ghost:active {
     background-color: transparent;
     border-color: #004299;
     border-color: var(--rs-btn-ghost-active-border);
     color: #004299;
     color: var(--rs-btn-ghost-active-text)
}

.rs-btn-ghost.rs-btn-disabled,
.rs-btn-ghost:disabled {
     background-color: transparent;
     border-color: #1675e0;
     border-color: var(--rs-btn-ghost-border);
     -webkit-box-shadow: none;
     box-shadow: none;
     color: #1675e0;
     color: var(--rs-btn-ghost-text);
     opacity: .3
}


.rs-btn-lg {
     font-size: 16px;
     line-height: 22px;
     padding: 10px 16px
}

.rs-btn-ghost.rs-btn-lg {
     padding: 9px 15px
}

.rs-btn-icon.rs-btn-lg {
     line-height: 20px;
     padding: 11px
}

.rs-btn-icon.rs-btn-lg>.rs-icon {
     font-size: 20px
}

.rs-btn-icon-with-text.rs-btn-lg {
     line-height: 22px
}

.rs-btn-icon-with-text.rs-btn-lg>.rs-icon {
     height: 42px;
     padding: 11px;
     width: 42px
}

.rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-left {
     padding: 10px 16px 10px 58px
}

.rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-left>.rs-icon {
     border-right: none;
     border-right: var(--rs-btn-default-border, none);
     left: 0
}

.rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-right {
     padding: 10px 58px 10px 16px
}

.rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-right>.rs-icon {
     border-left: none;
     border-left: var(--rs-btn-default-border, none);
     right: 0
}

.rs-btn-md {
     font-size: 14px;
     line-height: 20px;
     padding: 8px 12px
}

.rs-btn-ghost.rs-btn-md {
     padding: 7px 11px
}

.rs-btn-icon.rs-btn-md {
     line-height: 16px;
     padding: 10px
}

.rs-btn-icon.rs-btn-md>.rs-icon {
     font-size: 16px
}

.rs-btn-icon-with-text.rs-btn-md {
     line-height: 20px
}

.rs-btn-icon-with-text.rs-btn-md>.rs-icon {
     height: 36px;
     padding: 10px;
     width: 36px
}

.rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-left {
     padding: 8px 12px 8px 48px
}

.rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-left>.rs-icon {
     border-right: none;
     border-right: var(--rs-btn-default-border, none);
     left: 0
}

.rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-right {
     padding: 8px 48px 8px 12px
}

.rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-right>.rs-icon {
     border-left: none;
     border-left: var(--rs-btn-default-border, none);
     right: 0
}

.rs-btn-sm {
     font-size: 14px;
     line-height: 20px;
     padding: 5px 10px
}

.rs-btn-ghost.rs-btn-sm {
     padding: 4px 9px
}

.rs-btn-icon.rs-btn-sm {
     line-height: 16px;
     padding: 7px
}

.rs-btn-icon.rs-btn-sm>.rs-icon {
     font-size: 16px
}

.rs-btn-icon-with-text.rs-btn-sm {
     line-height: 20px
}

.rs-btn-icon-with-text.rs-btn-sm>.rs-icon {
     height: 30px;
     padding: 7px;
     width: 30px
}

.rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-left {
     padding: 5px 10px 5px 40px
}

.rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-left>.rs-icon {
     border-right: none;
     border-right: var(--rs-btn-default-border, none);
     left: 0
}

.rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-right {
     padding: 5px 40px 5px 10px
}

.rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-right>.rs-icon {
     border-left: none;
     border-left: var(--rs-btn-default-border, none);
     right: 0
}

.rs-btn-xs {
     font-size: 12px;
     line-height: 20px;
     padding: 2px 8px
}

.rs-btn-ghost.rs-btn-xs {
     padding: 1px 7px
}

.rs-btn-icon.rs-btn-xs {
     line-height: 12px;
     padding: 6px
}

.rs-btn-icon.rs-btn-xs>.rs-icon {
     font-size: 12px
}

.rs-btn-icon-with-text.rs-btn-xs {
     line-height: 20px
}

.rs-btn-icon-with-text.rs-btn-xs>.rs-icon {
     height: 24px;
     padding: 6px;
     width: 24px
}

.rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-left {
     padding: 2px 8px 2px 32px
}

.rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-left>.rs-icon {
     border-right: none;
     border-right: var(--rs-btn-default-border, none);
     left: 0
}

.rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-right {
     padding: 2px 32px 2px 8px
}

.rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-right>.rs-icon {
     border-left: none;
     border-left: var(--rs-btn-default-border, none);
     right: 0
}

.rs-btn-block {
     display: block;
     width: 100%
}

.rs-btn-block+.rs-btn-block {
     margin-top: 5px
}

.rs-btn-loading {
     color: transparent !important;
     cursor: default;
     pointer-events: none;
     position: relative
}

.rs-btn-loading>.rs-btn-spin:after,
.rs-btn-loading>.rs-btn-spin:before {
     border-radius: 50%;
     bottom: 0;
     content: "";
     height: 18px;
     left: 0;
     margin: auto;
     position: absolute;
     right: 0;
     top: 0;
     width: 18px;
     z-index: 1
}

.rs-btn-xs.rs-btn-loading>.rs-btn-spin:after,
.rs-btn-xs.rs-btn-loading>.rs-btn-spin:before {
     height: 16px;
     width: 16px
}

.rs-btn-loading>.rs-btn-spin:before {
     border: 3px solid rgb(from #f7f7fa rgb/80%);
     border: 3px solid var(--rs-loader-ring)
}

.rs-btn-primary.rs-btn-loading>.rs-btn-spin:before {
     border-color: rgba(248, 247, 250, .3)
}

.rs-theme-high-contrast .rs-btn-primary.rs-btn-loading>.rs-btn-spin:before {
     border-color: rgb(from #f7f7fa rgb/30%);
     border-color: var(--rs-loader-ring-inverse)
}

.rs-btn-loading>.rs-btn-spin:after {
     -webkit-animation: buttonSpin .6s linear infinite;
     animation: buttonSpin .6s linear infinite;
     border-color: #a6a6a6 transparent transparent;
     border-color: var(--rs-loader-rotor) transparent transparent;
     border-style: solid;
     border-width: 3px
}

.rs-btn-primary.rs-btn-loading>.rs-btn-spin:after {
     border-top-color: #fff
}

.rs-theme-high-contrast .rs-btn-primary.rs-btn-loading>.rs-btn-spin:after {
     border-top-color: #fff;
     border-top-color: var(--rs-loader-rotor-inverse)
}

@-webkit-keyframes buttonSpin {
     0% {
          -webkit-transform: rotate(0);
          transform: rotate(0)
     }

     to {
          -webkit-transform: rotate(1turn);
          transform: rotate(1turn)
     }
}

@keyframes buttonSpin {
     0% {
          -webkit-transform: rotate(0);
          transform: rotate(0)
     }

     to {
          -webkit-transform: rotate(1turn);
          transform: rotate(1turn)
     }
}

.rs-btn-group {
     display: inline-block;
     vertical-align: middle
}

.rs-btn-group,
.rs-btn-group>.rs-btn {
     position: relative
}

.rs-btn-group>.rs-btn:active,
.rs-btn-group>.rs-btn:focus {
     z-index: 2
}

.rs-btn-group:not(.rs-btn-group-vertical)>.rs-btn {
     float: left
}

.rs-btn-group:not(.rs-btn-group-vertical)>.rs-btn:not(:last-child) {
     border-bottom-right-radius: 0;
     border-top-right-radius: 0
}

.rs-btn-group:not(.rs-btn-group-vertical)>.rs-btn:not(:first-child) {
     border-bottom-left-radius: 0;
     border-top-left-radius: 0
}

.rs-btn-group:not(.rs-btn-group-vertical)>.rs-btn-ghost+.rs-btn-ghost {
     margin-left: -1px
}

.rs-btn-group-vertical>.rs-btn {
     display: block;
     max-width: 100%;
     width: 100%
}

.rs-btn-group-vertical>.rs-btn:not(:last-child) {
     border-bottom-left-radius: 0;
     border-bottom-right-radius: 0
}

.rs-btn-group-vertical>.rs-btn:not(:first-child) {
     border-top-left-radius: 0;
     border-top-right-radius: 0
}

.rs-btn-group-vertical>.rs-btn-ghost+.rs-btn-ghost {
     margin-top: -1px
}

.rs-btn-group-justified {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     width: 100%
}

.rs-btn-group-justified>.rs-btn {
     -webkit-box-flex: 1;
     -ms-flex: 1 1 1%;
     flex: 1 1 1%;
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap
}

.rs-btn-toolbar {
     line-height: 0
}

.rs-picker-subtle .picker-subtle-toggle {
     display: inline-block;
     padding-right: 32px;
     position: relative;
     z-index: 5
}

.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle {
     cursor: not-allowed
}

.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:active,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:focus,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:hover {
     background: none
}

.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:active:after,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:focus:after,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:hover:after {
     display: none
}

.rs-calendar {
     min-height: 266px;
     overflow: hidden;
     padding-top: 12px;
     position: relative
}

.rs-calendar-bordered .rs-calendar-table {
     border: 1px solid var(--pro-outline-border);
     border-radius: 6px
}

.rs-calendar-bordered .rs-calendar-table-header-row .rs-calendar-table-header-cell,
.rs-calendar-bordered .rs-calendar-table-row:not(:last-child) .rs-calendar-table-cell {
     border-bottom: 1px solid #f2f2f5;
     border-bottom: 1px solid var(--rs-border-secondary)
}

.rs-calendar-bordered .rs-calendar-month-dropdown {
     border: 1px solid var(--pro-outline-border);
     border-radius: 6px
}

.rs-calendar-panel .rs-calendar-header {
     padding-bottom: 6px;
     width: 100%
}

.rs-calendar-panel .rs-calendar-header:after,
.rs-calendar-panel .rs-calendar-header:before {
     content: " ";
     display: table
}

.rs-calendar-panel .rs-calendar-header:after {
     clear: both
}

.rs-calendar-panel .rs-calendar-header .rs-calendar-btn-today {
     float: right
}

.rs-calendar-panel .rs-calendar-header-backward,
.rs-calendar-panel .rs-calendar-header-forward {
     float: none !important
}

.rs-calendar-panel.rs-calendar-month-view .rs-calendar-header-backward,
.rs-calendar-panel.rs-calendar-month-view .rs-calendar-header-forward {
     display: inline-block;
     pointer-events: none;
     visibility: hidden
}

.rs-calendar-panel .rs-calendar-header-month-toolbar {
     width: auto !important
}

.rs-calendar-panel .rs-calendar-month-view,
.rs-calendar-panel.rs-calendar-month-view .rs-calendar-header-month-toolbar {
     padding-left: 0;
     padding-right: 0
}

.rs-calendar-panel .rs-calendar-table-header-row .rs-calendar-table-header-cell-content {
     padding-bottom: 8px;
     padding-top: 8px
}

.rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-day {
     background-color: #3498ff;
     background-color: var(--rs-calendar-today-bg);
     border-radius: 50%;
     color: #fff;
     color: var(--rs-calendar-today-text);
     height: 20px;
     margin-left: auto;
     margin-right: auto;
     width: 20px
}

.rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
     -webkit-box-shadow: none;
     box-shadow: none
}

.rs-calendar-panel .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
     background-color: inherit;
     -webkit-box-shadow: inset 0 0 0 1px #3498ff;
     -webkit-box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
     box-shadow: inset 0 0 0 1px #3498ff;
     box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
     color: inherit
}

.rs-calendar-panel .rs-calendar-table-cell-day {
     display: block;
     margin-top: 3px;
     text-align: center
}

.rs-calendar-panel .rs-calendar-month-dropdown {
     margin-left: 12px;
     margin-right: 12px;
     margin-top: -2px;
     top: 54px;
     width: calc(100% - 24px)
}

.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-month-dropdown-scroll {
     height: 644px
}

.rs-calendar-panel.rs-calendar-compact .rs-calendar-month-dropdown-scroll {
     height: 344px
}

.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
     height: 100px;
     overflow: hidden;
     width: 100%
}

.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-cell {
     vertical-align: top
}

.rs-calendar-panel .rs-calendar-table-cell {
     line-height: 0;
     padding: 0 1px
}

.rs-calendar-panel .rs-calendar-table-cell-content {
     border-radius: 0;
     width: 100%
}

.rs-calendar-panel .rs-calendar-table-row:last-child :first-child .rs-calendar-table-cell-content {
     border-bottom-left-radius: 6px
}

.rs-calendar-panel .rs-calendar-table-row:last-child :last-child .rs-calendar-table-cell-content {
     border-bottom-right-radius: 6px
}

.rs-calendar-panel.rs-calendar-compact .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
     height: 50px
}

.rs-calendar-btn-close {
     -webkit-box-pack: center;
     -ms-flex-pack: center;
     background: #fff;
     background: var(--pro-light);
     border: 0;
     bottom: 0;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     height: 14px;
     justify-content: center;
     left: 0;
     padding: 0;
     position: absolute;
     right: 0;
     z-index: 1
}

.rs-calendar-panel .rs-calendar-btn-close {
     bottom: 11px;
     margin: 0 20px
}

.rs-calendar-month-view .rs-calendar-header-month-toolbar {
     padding-left: 24px;
     padding-right: 24px
}

.rs-calendar-month-view .rs-calendar-header-backward,
.rs-calendar-month-view .rs-calendar-header-forward {
     display: none
}

.rs-calendar-month-view .rs-calendar-header-title-date {
     background: transparent;
     color: #1675e0;
     color: var(--pro-primary)
}

.rs-calendar-month-view .rs-calendar-header-title-date.rs-calendar-header-error {
     color: #f44336;
     color: var(--rs-text-error)
}

.rs-calendar-month-view .rs-calendar-header-title-date.rs-calendar-header-error:focus,
.rs-calendar-month-view .rs-calendar-header-title-date.rs-calendar-header-error:hover:active {
     color: #fff !important
}

.rs-calendar-time-view .rs-calendar-header-title-time {
     background: transparent;
     color: #1675e0;
     color: var(--pro-primary)
}

.rs-calendar-time-view .rs-calendar-header-title-time.rs-calendar-header-error {
     color: #f44336;
     color: var(--rs-text-error)
}

.rs-calendar-time-view .rs-calendar-header-title-time.rs-calendar-header-error:hover:active {
     color: #fff
}

.rs-calendar-table-cell-in-range,
.rs-calendar-table-cell-selected {
     position: relative
}

.rs-calendar-table-cell-in-range:before,
.rs-calendar-table-cell-selected:before {
     content: "";
     display: block;
     height: 24px;
     margin-top: 4px;
     position: absolute;
     top: 0;
     width: 100%;
     z-index: 0
}

.rs-calendar-table-cell-in-range .rs-calendar-table-cell-content,
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
     position: relative;
     z-index: 1
}

.rs-calendar-table-cell-in-range:before {
     background-color: rgb(from #cce9ff rgb/50%);
     background-color: var(--rs-calendar-range-bg)
}

.rs-calendar-table-cell-in-range:hover:before {
     display: none
}

.rs-calendar-month-dropdown-scroll div:focus {
     outline: none
}

.rs-calendar-header {
     padding-left: 12px;
     padding-right: 12px;
     width: 280px
}

.rs-calendar-header:after,
.rs-calendar-header:before {
     content: " ";
     display: table
}

.rs-calendar-header:after {
     clear: both
}

.rs-calendar-header-month-toolbar,
.rs-calendar-header-time-toolbar {
     display: inline-block
}

.rs-calendar-header-month-toolbar {
     float: left
}

.rs-calendar-header-time-toolbar {
     float: right
}

.rs-calendar-header-title {
     margin: 0 4px
}

.rs-calendar-header-meridian {
     font-size: 12px;
     margin-left: 4px
}

.rs-calendar-header-error {
     --rs-btn-subtle-text: var(--rs-red-500);
     --rs-btn-subtle-hover-bg: var(--rs-red-500);
     --rs-btn-subtle-hover-text: #fff;
     --rs-btn-subtle-active-bg: var(--rs-red-600);
     --rs-btn-subtle-active-text: #fff;
     background-color: transparent;
     border: none;
     color: var(--rs-btn-subtle-text)
}

.rs-calendar-header-error:focus,
.rs-calendar-header-error:hover {
     background-color: #e5e5ea;
     background-color: var(--rs-btn-subtle-hover-bg);
     color: var(--rs-btn-subtle-hover-text)
}

.rs-calendar-header-error.rs-btn-active,
.rs-calendar-header-error:active {
     background-color: #e5e5ea;
     background-color: var(--rs-btn-subtle-active-bg);
     color: #272c36;
     color: var(--rs-btn-subtle-active-text)
}

.rs-calendar-header-error.rs-btn-disabled,
.rs-calendar-header-error:disabled {
     background: none;
     color: #c5c6c7;
     color: var(--rs-btn-subtle-disabled-text)
}

.rs-theme-high-contrast .rs-calendar-header-error.rs-btn-disabled,
.rs-theme-high-contrast .rs-calendar-header-error:disabled {
     opacity: .5
}

.rs-theme-dark .rs-calendar-header-error {
     --rs-btn-subtle-hover-bg: var(--rs-red-600);
     --rs-btn-subtle-active-bg: var(--rs-red-400)
}

.rs-calendar-header-btn-disabled,
.rs-calendar-header-btn-disabled:hover,
.rs-calendar-header-btn-disabled:hover:focus {
     background: none;
     cursor: not-allowed;
     opacity: .3
}

.rs-calendar-header-btn-disabled:after {
     display: none !important
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) {
     margin: 0 auto;
     padding-left: 12px;
     padding-right: 12px
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar {
     display: block;
     text-align: center;
     width: 100%
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar:after,
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar:before {
     content: " ";
     display: table
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar:after {
     clear: both
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-backward {
     float: left
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-forward {
     float: right
}

.rs-calendar-header-has-time:not(.rs-calendar-header-has-month) {
     margin: 0 auto;
     text-align: center
}

.rs-calendar-header-has-time:not(.rs-calendar-header-has-month) .rs-calendar-header-time-toolbar {
     display: inline-block;
     float: none
}

.rs-calendar-header-has-month.rs-calendar-header-has-time {
     margin: 0 auto
}

[dir=rtl] .rs-calendar-header-backward .rs-icon,
[dir=rtl] .rs-calendar-header-forward .rs-icon {
     -webkit-transform: rotate(180deg);
     transform: rotate(180deg)
}

.rs-calendar-body {
     padding: 4px 12px 12px
}

.rs-calendar-table {
     display: table;
     table-layout: fixed;
     width: 100%
}

.rs-calendar-table-row {
     display: table-row
}

.rs-calendar-table-row:nth-child(2) .rs-calendar-table-cell-week-number {
     border-top-left-radius: 6px;
     border-top-right-radius: 6px
}

.rs-calendar-table-row:last-child .rs-calendar-table-cell-week-number {
     border-bottom-left-radius: 6px;
     border-bottom-right-radius: 6px
}

.rs-calendar-table-cell,
.rs-calendar-table-header-cell {
     display: table-cell;
     padding: 1px 0;
     text-align: center;
     vertical-align: middle;
     width: 1%
}

.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-header-cell-content,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-un-same-month .rs-calendar-table-cell-content,
.rs-calendar-table-header-cell-un-same-month .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-un-same-month .rs-calendar-table-header-cell-content,
.rs-calendar-table-header-cell-un-same-month .rs-calendar-table-header-cell-content:hover {
     color: #c5c6c7;
     color: var(--rs-text-disabled)
}

.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content:hover {
     background: none;
     cursor: not-allowed;
     text-decoration: line-through
}

.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover {
     background-color: #3498ff;
     background-color: var(--pro-primary);
     -webkit-box-shadow: none;
     box-shadow: none;
     color: #fff;
     color: var(--pro-light)
}

.rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover {
     text-decoration: underline
}

.rs-calendar-table-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content,
.rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content {
     opacity: .3
}

.rs-calendar-table-cell-content {
     border-radius: 3px;
     cursor: pointer;
     display: inline-block;
     font-size: 14px;
     line-height: 1.42857143;
     padding: 5px
}

.rs-calendar-table-cell-content:hover {
     background-color: #f2faff;
     background-color: var(--rs-state-hover-bg)
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
     -webkit-box-shadow: inset 0 0 0 1px #3498ff;
     -webkit-box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
     box-shadow: inset 0 0 0 1px #3498ff;
     box-shadow: inset 0 0 0 1px var(--rs-input-focus-border)
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
     background-color: #3498ff;
     background-color: var(--pro-primary);
     -webkit-box-shadow: none;
     box-shadow: none;
     color: #fff;
     color: var(--pro-light)
}

.rs-theme-high-contrast .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
     text-decoration: underline
}

.rs-calendar-table-header-cell-content {
     color: #8e8e93;
     color: var(--rs-text-secondary);
     display: inline-block;
     font-size: 12px;
     line-height: 1.66666667;
     padding-bottom: 2px;
     padding-top: 2px
}

.rs-calendar-table-header-cell-content,
.rs-calendar-table-header-cell-content:focus,
.rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-content:hover:focus {
     background: none;
     cursor: auto
}

.rs-calendar-table-cell-week-number {
     background-color: #f7f7fa;
     background-color: var(--rs-bg-well);
     color: #8e8e93;
     color: var(--rs-text-secondary);
     display: table-cell;
     font-size: 12px;
     min-width: 30px;
     padding: 1px;
     text-align: center;
     vertical-align: middle
}

.rs-calendar-month-dropdown {
     background-color: #fff;
     background-color: var(--pro-light);
     border-top: 1px solid #e5e5ea;
     border-top: 1px solid var(--pro-outline-border);
     display: none;
     margin-top: 0;
     overflow: hidden;
     position: absolute;
     top: 44px;
     width: 100%;
     z-index: 1
}

.rs-calendar-month-view .rs-calendar-month-dropdown {
     display: block
}

.rs-calendar-month-view .rs-calendar-month-dropdown-content {
     -webkit-animation: slideDown .3s linear;
     animation: slideDown .3s linear
}

.rs-calendar-month-dropdown-scroll {
     height: 230px
}

.rs-calendar-month-dropdown-row {
     padding: 5px 12px 5px 50px;
     position: relative
}

.rs-calendar-month-dropdown-row:not(:last-child) {
     border-bottom: 1px dashed #e5e5ea;
     border-bottom: 1px dashed var(--pro-outline-border)
}

.rs-calendar-month-dropdown-year {
     left: 12px;
     position: absolute;
     top: calc(50% - .5em)
}

.rs-calendar-month-dropdown-year-active {
     color: #1675e0;
     color: var(--pro-primary)
}

.rs-calendar-month-dropdown-list {
     display: block;
     min-width: 200px
}

.rs-calendar-month-dropdown-list:after,
.rs-calendar-month-dropdown-list:before {
     content: " ";
     display: table
}

.rs-calendar-month-dropdown-list:after {
     clear: both
}

.rs-calendar-month-dropdown-cell {
     display: inline-block;
     float: left;
     margin: 1px;
     text-align: center;
     vertical-align: middle;
     width: calc(16.66667% - 2px)
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
     .rs-calendar-month-dropdown-cell {
          width: calc(16.66667% - 2.16667px)
     }
}

@supports (-ms-ime-align:auto) {
     .rs-calendar-month-dropdown-cell {
          width: calc(16.66667% - 2.16667px)
     }
}

.rs-calendar-month-dropdown-cell-content {
     border-radius: 3px;
     cursor: pointer;
     display: inline-block;
     font-size: 14px;
     height: 30px;
     line-height: 1.42857143;
     padding: 5px;
     width: 30px
}

.rs-calendar-month-dropdown-cell-content:hover {
     background-color: #f2faff;
     background-color: var(--rs-state-hover-bg)
}

.rs-calendar-month-dropdown-cell:not(.rs-calendar-month-dropdown-cell-active).disabled .rs-calendar-month-dropdown-cell-content {
     background: none;
     color: #c5c6c7;
     color: var(--rs-text-disabled);
     cursor: not-allowed;
     text-decoration: line-through
}

.rs-calendar-month-dropdown-cell-active.disabled .rs-calendar-month-dropdown-cell-content {
     cursor: not-allowed;
     opacity: .3
}

.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
     background-color: #3498ff;
     background-color: var(--pro-primary);
     -webkit-box-shadow: none;
     box-shadow: none;
     color: #fff;
     color: var(--pro-light)
}

.rs-theme-high-contrast .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
     text-decoration: underline
}

.rs-calendar-time-dropdown {
     background-color: #fff;
     background-color: var(--pro-light);
     color: var(--rs-text-primary);
     display: none;
     overflow: hidden;
     padding-left: 12px;
     padding-right: 12px;
     position: absolute;
     top: 44px;
     width: 100%;
     z-index: 1
}

.rs-calendar-time-view .rs-calendar-time-dropdown {
     display: block
}

.rs-calendar-time-view .rs-calendar-time-dropdown-content {
     -webkit-animation: slideDown .3s linear;
     animation: slideDown .3s linear
}

.rs-calendar-time-dropdown-row {
     display: table;
     width: 100%
}

.rs-calendar-time-dropdown-column {
     display: table-cell;
     width: 1%
}

.rs-calendar-time-dropdown-column>ul,
.rs-calendar-time-dropdown-column>ul>li {
     list-style: none;
     margin: 0;
     padding: 0
}

.rs-calendar-time-dropdown-column>ul {
     height: 230px;
     overflow-y: auto;
     padding-bottom: 200px
}

.rs-calendar-time-dropdown-column-title {
     background-color: #f7f7fa;
     background-color: var(--pro-secondary);
     color: #8e8e93;
     color: var(--rs-text-secondary);
     font-size: 12px;
     line-height: 1.66666667;
     padding-bottom: 2px;
     padding-top: 2px;
     text-align: center;
     width: 100%
}

.rs-calendar-time-dropdown-cell {
     color: inherit;
     cursor: pointer;
     display: block;
     font-size: 14px;
     line-height: 20px;
     padding: 5px;
     text-align: center
}

.rs-calendar-time-dropdown-cell,
.rs-calendar-time-dropdown-cell:focus,
.rs-calendar-time-dropdown-cell:hover {
     text-decoration: none
}

.rs-calendar-time-dropdown-cell:hover {
     background-color: rgb(from #cce9ff rgb/50%);
     background-color: var(--pro-nav-btn-link-hover);
     color: #1675e0;
     color: var(--rs-listbox-option-hover-text)
}

.rs-theme-high-contrast .rs-calendar-time-dropdown-cell:hover {
     color: #1675e0;
     color: var(--rs-listbox-option-hover-text);
     outline: 3px solid rgb(from #3498ff rgb/25%);
     outline: 3px solid var(--rs-color-focus-ring);
     outline-offset: -3px;
     outline-width: 2px;
     text-decoration: underline
}

.rs-theme-high-contrast .rs-theme-high-contrast .rs-calendar-time-dropdown-cell:hover {
     outline-offset: 2px
}

.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
     background-color: #3498ff;
     background-color: var(--pro-primary);
     color: #fff;
     color: var(--pro-light)
}

.rs-theme-high-contrast .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active:hover {
     background-color: rgb(from #cce9ff rgb/50%);
     background-color: var(--pro-nav-btn-link-hover);
     color: #1675e0;
     color: var(--rs-listbox-option-hover-text)
}

.rs-theme-high-contrast .rs-theme-high-contrast .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active:hover {
     color: #1675e0;
     color: var(--rs-listbox-option-hover-text);
     outline: 3px solid rgb(from #3498ff rgb/25%);
     outline: 3px solid var(--rs-color-focus-ring);
     outline-offset: -3px;
     outline-width: 2px;
     text-decoration: underline
}

.rs-theme-high-contrast .rs-theme-high-contrast .rs-theme-high-contrast .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active:hover {
     outline-offset: 2px
}

.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-disabled {
     background: none;
     color: #c5c6c7;
     color: var(--rs-text-disabled);
     cursor: not-allowed;
     text-decoration: line-through
}

.rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell-disabled.rs-calendar-time-dropdown-cell {
     cursor: not-allowed;
     opacity: .3
}

.rs-form-control-label {
     margin-bottom: 4px
}

.rs-form-plaintext .rs-form-control-label {
     color: #8e8e93;
     color: var(--rs-text-secondary)
}

.rs-picker-toolbar {
     border-top: 1px solid #e5e5ea;
     border-top: 1px solid var(--pro-outline-border);
     padding: 12px
}

.rs-picker-date-inline {
     height: 299px
}

.rs-picker-date-inline .rs-calendar {
     height: 286px
}

.rs-picker-date .rs-input-group-addon {
     color: #8e8e93;
     color: var(--rs-text-secondary)
}

.rs-picker-date .rs-input-group-addon .rs-btn-close {
     padding: 0
}

.rs-picker-date.rs-picker-subtle .rs-input-group {
     border-color: transparent
}

.rs-picker-date>.rs-input-group.rs-input-group-inside .rs-input {
     padding-right: 0
}

.rs-picker-date-predefined {
     border-right: 1px solid #e5e5ea;
     border-right: 1px solid var(--pro-outline-border);
     height: 325px;
     padding: 4px 0
}

.rs-picker-date-predefined .rs-btn {
     display: block
}

.rs-picker-popup.rs-picker-popup-date {
     padding: 0
}

.rs-picker-popup .rs-picker-toolbar {
     max-width: 100%
}

.rs-picker-popup .rs-picker-toolbar-ranges {
     max-width: 400px
}

.rs-picker-popup .rs-calendar {
     display: block;
     margin: 0 auto;
     min-width: 264px
}

.rs-picker-popup .rs-calendar-show-week-numbers {
     min-width: 278px
}

.rs-picker-popup .rs-calendar-show-week-numbers .rs-calendar-body {
     padding-left: 12px;
     padding-right: 12px
}

.rs-picker-popup .rs-calendar-body {
     padding-left: 15px;
     padding-right: 15px
}

.rs-picker-popup .rs-calendar-table {
     width: unset
}

.rs-picker-popup .rs-calendar-month-dropdown-cell:focus-visible .rs-calendar-month-dropdown-cell-content {
     outline: 3px solid rgb(from #3498ff rgb/25%);
     outline: 3px solid var(--rs-color-focus-ring)
}

.rs-picker-popup .rs-calendar-table-cell:focus-visible {
     outline: none
}

.rs-picker-popup .rs-calendar-table-cell:focus-visible .rs-calendar-table-cell-content {
     outline: 3px solid rgb(from #3498ff rgb/25%);
     outline: 3px solid var(--rs-color-focus-ring)
}

.rs-picker-popup .rs-calendar .rs-calendar-table-cell-content {
     height: 30px;
     width: 30px
}

.rs-picker-popup .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
     background-color: rgb(from #cce9ff rgb/50%);
     background-color: var(--pro-nav-btn-link-hover);
     color: #1675e0;
     color: var(--rs-listbox-option-hover-text)
}

.rs-theme-high-contrast .rs-picker-popup .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
     color: #1675e0;
     color: var(--rs-listbox-option-hover-text);
     outline: 3px solid rgb(from #3498ff rgb/25%);
     outline: 3px solid var(--rs-color-focus-ring);
     outline-offset: -3px;
     outline-width: 2px;
     text-decoration: underline
}

.rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-popup .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
     outline-offset: 2px
}

.rs-picker-popup .rs-calendar .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content:hover {
     background-color: #1675e0;
     background-color: var(--rs-calendar-cell-selected-hover-bg);
     color: #fff;
     color: var(--pro-light)
}

.rs-theme-high-contrast .rs-picker-popup .rs-calendar .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content:hover {
     color: #fff;
     color: var(--pro-light)
}

.rs-picker-popup .rs-calendar .rs-calendar-table-header-row .rs-calendar-table-cell-content {
     height: 24px;
     padding-top: 0
}

.rs-picker-popup .rs-calendar .rs-calendar-table-cell-content {
     display: inline-block;
     padding-left: 0;
     padding-right: 0
}

.rs-picker-popup .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
     background-color: rgb(from #cce9ff rgb/50%);
     background-color: var(--pro-nav-btn-link-hover);
     color: #1675e0;
     color: var(--rs-listbox-option-hover-text)
}

.rs-theme-high-contrast .rs-picker-popup .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
     color: #1675e0;
     color: var(--rs-listbox-option-hover-text);
     outline: 3px solid rgb(from #3498ff rgb/25%);
     outline: 3px solid var(--rs-color-focus-ring);
     outline-offset: -3px;
     outline-width: 2px;
     text-decoration: underline
}

.rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-popup .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
     outline-offset: 2px
}

.rs-picker-popup .rs-calendar .rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content {
     background-color: #1675e0;
     background-color: var(--pro-primary-hover);
     color: #fff;
     color: var(--pro-light)
}

.rs-theme-high-contrast .rs-picker-popup .rs-calendar .rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content {
     color: #fff;
     color: var(--pro-light)
}

.rs-picker-popup .rs-calendar .rs-calendar-month-dropdown-scroll {
     height: 227px
}

.rs-picker-popup .rs-calendar .rs-calendar-time-dropdown-column>ul {
     height: 198px;
     padding-bottom: 0; //changes
}

.rs-picker-daterange .rs-input-group-addon {
     color: #8e8e93;
     color: var(--rs-text-secondary)
}

.rs-picker-daterange .rs-input-group-addon .rs-btn-close {
     padding: 0
}

.rs-picker-daterange.rs-picker-subtle .rs-input-group {
     border-color: transparent
}

.rs-picker-daterange>.rs-input-group.rs-input-group-inside .rs-input {
     padding-right: 0
}

.rs-picker-popup.rs-picker-popup-daterange {
     padding: 0
}

.rs-picker-popup .rs-calendar {
     display: inline-block;
     height: 274px;
     padding-bottom: 12px
}

.rs-picker-popup .rs-calendar:first-child {
     border-right: 1px solid #e5e5ea;
     border-right: 1px solid var(--pro-outline-border)
}

.rs-picker-popup .rs-calendar-header {
     text-align: center;
     width: 100%
}

.rs-picker-popup .rs-calendar-header-month-toolbar {
     float: none
}

.rs-picker-popup .rs-calendar-month-dropdown {
     z-index: 1
}

.rs-picker-popup .rs-calendar-month-dropdown-list {
     width: 185px
}

.rs-picker-popup .rs-picker-daterange-panel-show-one-calendar .rs-picker-toolbar {
     max-width: 255px
}

.rs-picker-popup .rs-picker-daterange-panel-show-one-calendar .rs-picker-toolbar-ranges {
     width: 190px
}

.rs-picker-popup .rs-picker-daterange-calendar-single .rs-calendar {
     border: 0;
     display: block;
     margin: auto
}

.rs-picker-daterange-header {
     border-bottom: 1px solid #e5e5ea;
     border-bottom: 1px solid var(--pro-outline-border);
     font-size: 14px;
     line-height: 1.42857143;
     padding: 8px 12px
}

.rs-picker-daterange-calendar-group {
     height: 274px;
     min-width: 492px
}

.rs-picker-daterange-predefined {
     border-right: 1px solid #e5e5ea;
     border-right: 1px solid var(--pro-outline-border);
     height: 366px;
     padding: 4px 0
}

.rs-picker-daterange-predefined .rs-btn {
     display: block
}

.rs-divider {
     background-color: #e5e5ea;
     background-color: var(--rs-divider-border)
}

.rs-divider-vertical {
     display: inline-block;
     height: 1em;
     margin: 0 12px;
     vertical-align: middle;
     width: 1px
}

.rs-divider-horizontal {
     height: 1px;
     margin: 24px 0
}

.rs-divider-horizontal.rs-divider-with-text {
     -webkit-box-orient: horizontal;
     -webkit-box-direction: normal;
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     background: transparent;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -ms-flex-direction: row;
     flex-direction: row;
     margin: 30px 0
}

.rs-divider-horizontal.rs-divider-with-text:after,
.rs-divider-horizontal.rs-divider-with-text:before {
     -webkit-box-flex: 1;
     border-top: 1px solid #e5e5ea;
     border-top: 1px solid var(--rs-divider-border);
     content: "";
     -ms-flex: 1 1 auto;
     flex: 1 1 auto
}

.rs-divider-inner-text {
     -webkit-box-flex: 0;
     -ms-flex: 0 0 auto;
     flex: 0 0 auto;
     padding: 0 12px
}

.rs-btn-icon>.rs-icon {
     vertical-align: bottom
}

.rs-btn-icon-with-text>.rs-icon {
     background-color: #f2f2f5;
     background-color: var(--rs-iconbtn-addon);
     display: block;
     position: absolute;
     top: 0;
     -webkit-transition: color .15s ease-out, background-color .15s ease-out;
     transition: color .15s ease-out, background-color .15s ease-out
}

.rs-btn-icon-with-text:focus>.rs-icon,
.rs-btn-icon-with-text:hover>.rs-icon {
     background-color: #d9d9d9;
     background-color: var(--rs-iconbtn-activated-addon)
}

.rs-btn-icon-with-text.rs-btn-active>.rs-icon,
.rs-btn-icon-with-text:active>.rs-icon {
     background-color: #c5c6c7;
     background-color: var(--rs-iconbtn-pressed-addon)
}

.rs-btn-icon-with-text.rs-btn-disabled>.rs-icon,
.rs-btn-icon-with-text:disabled>.rs-icon {
     background-color: #f2f2f5;
     background-color: var(--rs-iconbtn-addon)
}

.rs-theme-high-contrast .rs-btn-icon-with-text.rs-btn-disabled,
.rs-theme-high-contrast .rs-btn-icon-with-text:disabled {
     opacity: .5
}

.rs-btn-icon-with-text.rs-btn-primary>.rs-icon {
     background-color: #2589f5;
     background-color: var(--rs-iconbtn-primary-addon)
}

.rs-btn-icon-with-text.rs-btn-primary:focus>.rs-icon,
.rs-btn-icon-with-text.rs-btn-primary:hover>.rs-icon {
     background-color: #1675e0;
     background-color: var(--rs-iconbtn-primary-activated-addon)
}

.rs-btn-icon-with-text.rs-btn-primary.rs-btn-active>.rs-icon,
.rs-btn-icon-with-text.rs-btn-primary:active>.rs-icon {
     background-color: #0a5dc2;
     background-color: var(--rs-iconbtn-primary-pressed-addon)
}

.rs-btn-icon-with-text.rs-btn-primary.rs-btn-disabled>.rs-icon,
.rs-btn-icon-with-text.rs-btn-primary:disabled>.rs-icon {
     background-color: #2589f5;
     background-color: var(--rs-iconbtn-primary-addon)
}

.rs-theme-high-contrast .rs-btn-icon-with-text.rs-btn-primary.rs-btn-disabled,
.rs-theme-high-contrast .rs-btn-icon-with-text.rs-btn-primary:disabled {
     opacity: .5
}

.rs-btn-icon-circle {
     border-radius: 50% !important
}

.rs-input {
     background-color: #fff;
     background-color: var(--rs-input-bg);
     background-image: none;
     border: 1px solid var(--pro-outline-border);
     border-radius: 3px;
     color: var(--rs-text-primary);
     display: block;
     font-size: 14px;
     line-height: 1.42857143;
     padding: 7px 11px;
     -webkit-transition: border-color .15s ease-in-out;
     transition: border-color .15s ease-in-out;
     width: 100%
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
     .rs-input {
          height: 36px
     }
}

textarea.rs-input {
     height: auto
}

.rs-theme-high-contrast .rs-input {
     -webkit-transition: none;
     transition: none
}

.rs-input::-webkit-input-placeholder {
     color: #8e8e93;
     color: var(--rs-text-secondary)
}

.rs-input::-moz-placeholder {
     color: #8e8e93;
     color: var(--rs-text-secondary)
}

.rs-input::-ms-input-placeholder {
     color: #8e8e93;
     color: var(--rs-text-secondary)
}

.rs-input::placeholder {
     color: #8e8e93;
     color: var(--rs-text-secondary)
}

.rs-input:focus,
.rs-input:hover:not(:disabled) {
     border-color: #3498ff;
     border-color: var(--rs-input-focus-border)
}

.rs-input:focus {
     outline: 3px solid rgb(from #3498ff rgb/25%);
     outline: 3px solid var(--rs-color-focus-ring)
}

.rs-theme-high-contrast .rs-input:focus {
     outline-offset: 2px
}

.rs-input:disabled {
     background-color: #f7f7fa;
     background-color: var(--rs-input-disabled-bg);
     color: #c5c6c7;
     color: var(--rs-text-disabled)
}

textarea.rs-input {
     overflow: auto;
     resize: vertical
}

.rs-input-xs {
     font-size: 12px;
     line-height: 1.66666667;
     padding: 1px 11px
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
     .rs-input-xs {
          height: 24px
     }
}

textarea.rs-input-xs {
     height: auto
}

.rs-input-lg {
     font-size: 16px;
     line-height: 1.375;
     padding: 9px 11px
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
     .rs-input-lg {
          height: 42px
     }
}

textarea.rs-input-lg {
     height: auto
}

.rs-input-sm {
     font-size: 14px;
     line-height: 1.42857143;
     padding: 4px 11px
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
     .rs-input-sm {
          height: 30px
     }
}

textarea.rs-input-sm {
     height: auto
}

.rs-input-group {
     border: 1px solid var(--pro-outline-border);
     border-radius: 3px;
     min-height: 40px;
     color: var(--rs-text-primary);
     cursor: text;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     position: relative;
     -webkit-transition: border-color .15s ease-in-out;
     transition: border-color .15s ease-in-out;
     width: 100%
}

.rs-theme-high-contrast .rs-input-group {
     -webkit-transition: none;
     transition: none
}

.rs-input-group:focus-within,
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:not(.rs-input-group-disabled):hover {
     border-color: #3498ff;
     border-color: var(--rs-input-focus-border)
}

.rs-input-group:focus-within,
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus {
     outline: 3px solid rgb(from #3498ff rgb/25%);
     outline: 3px solid var(--rs-color-focus-ring)
}

.rs-theme-high-contrast .rs-input-group:focus-within,
.rs-theme-high-contrast .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus {
     outline-offset: 2px
}

.rs-input-group .rs-input-group {
     border-color: transparent !important;
     outline: none !important
}

.rs-input-group .rs-input-group-addon,
.rs-input-group .rs-input-group-btn,
.rs-input-group .rs-input-number,
.rs-input-group .rs-picker-date .rs-picker-toggle,
.rs-input-group:not(.rs-input-group-inside) .rs-input {
     border: none;
     border-radius: 0;
     outline: none
}

.rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:last-child) .rs-input-number-btn-group-vertical {
     border-radius: 0
}

.rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:last-child) .rs-input-number-touchspin-up {
     border-top-right-radius: 0
}

.rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:last-child) .rs-input-number-touchspin-down {
     border-bottom-right-radius: 0
}

.rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:first-child) .rs-input {
     border-radius: 0 !important
}

.rs-input-group:not(.rs-input-group-inside) .rs-auto-complete:first-child .rs-input,
.rs-input-group:not(.rs-input-group-inside) .rs-form-control-wrapper:first-child>.rs-input,
.rs-input-group:not(.rs-input-group-inside) .rs-picker-date:first-child .rs-picker-toggle,
.rs-input-group:not(.rs-input-group-inside)>:first-child {
     border-bottom-left-radius: 6px;
     border-top-left-radius: 6px
}

.rs-input-group:not(.rs-input-group-inside) .rs-auto-complete:last-child .rs-input,
.rs-input-group:not(.rs-input-group-inside) .rs-form-control-wrapper:last-child>.rs-input,
.rs-input-group:not(.rs-input-group-inside) .rs-picker-date:last-child .rs-picker-toggle,
.rs-input-group:not(.rs-input-group-inside)>:last-child {
     border-bottom-right-radius: 6px;
     border-top-right-radius: 6px
}

.rs-input-group>.rs-auto-complete,
.rs-input-group>.rs-form-control-wrapper,
.rs-input-group>.rs-input {
     -webkit-box-flex: 1;
     -ms-flex: 1 1 auto;
     flex: 1 1 auto
}

.rs-input-group .rs-form-control-wrapper>.rs-input {
     width: 100%
}

.rs-input-group>.rs-auto-complete,
.rs-input-group>.rs-input {
     position: relative
}

.rs-input-group .rs-form-control-wrapper~.rs-input-group-addon,
.rs-input-group input.rs-input~.rs-input-group-addon {
     border-left: none;
     left: auto;
     right: 0
}

.rs-input-group .rs-form-control-wrapper~.rs-input-group-addon:not(:last-child),
.rs-input-group input.rs-input~.rs-input-group-addon:not(:last-child) {
     border-right: 0
}

.rs-input-group.rs-input-group-inside {
     background-color: #fff;
     background-color: var(--rs-input-bg);
     width: 100%
}

.rs-input-group.rs-input-group-inside .rs-input {
     border: none;
     display: block;
     outline: none;
     width: 100%
}

.rs-input-group.rs-input-group-inside .rs-input-group-addon,
.rs-input-group.rs-input-group-inside .rs-input-group-btn {
     -webkit-box-flex: 0;
     -ms-flex: 0 0 auto;
     flex: 0 0 auto;
     width: auto
}

.rs-input-group.rs-input-group-inside .rs-input-group-btn {
     background-color: transparent;
     border: none;
     border-radius: 0 6px 6px 0;
     color: var(--rs-btn-subtle-text);
     color: inherit;
     height: 34px;
     padding: 7px 11px
}

.rs-input-group.rs-input-group-inside .rs-input-group-btn:focus,
.rs-input-group.rs-input-group-inside .rs-input-group-btn:hover {
     background-color: #e5e5ea;
     background-color: var(--rs-btn-subtle-hover-bg);
     color: var(--rs-btn-subtle-hover-text)
}

.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-active,
.rs-input-group.rs-input-group-inside .rs-input-group-btn:active {
     background-color: #e5e5ea;
     background-color: var(--rs-btn-subtle-active-bg);
     color: #272c36;
     color: var(--rs-btn-subtle-active-text)
}

.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-disabled,
.rs-input-group.rs-input-group-inside .rs-input-group-btn:disabled {
     background: none;
     color: #c5c6c7;
     color: var(--rs-btn-subtle-disabled-text)
}

.rs-theme-high-contrast .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-disabled,
.rs-theme-high-contrast .rs-input-group.rs-input-group-inside .rs-input-group-btn:disabled {
     opacity: .5
}

.rs-input-group.rs-input-group-inside .rs-input-group-btn>.rs-icon {
     font-size: 14px;
     line-height: 1.42857143
}

.rs-input-group.rs-input-group-inside .rs-input-group-btn:focus {
     outline: none
}

.rs-input-group.rs-input-group-inside .rs-input-group-btn:first-child {
     border-bottom-left-radius: 6px;
     border-top-left-radius: 6px
}

.rs-input-group.rs-input-group-inside .rs-input-group-btn:last-child {
     border-bottom-right-radius: 6px;
     border-top-right-radius: 6px
}

.rs-input-group.rs-input-group-inside .rs-input-group-addon {
     background: none;
     border: none;
     padding: 10px 12px;
     top: 0
}

.rs-input-group.rs-input-group-inside .rs-input-group-addon.rs-input-group-btn {
     padding: 8px 13px
}

.rs-input-group.rs-input-group-inside .rs-input-group-addon~.rs-auto-complete>input.rs-input,
.rs-input-group.rs-input-group-inside .rs-input-group-addon~.rs-form-control-wrapper>input.rs-input,
.rs-input-group.rs-input-group-inside .rs-input-group-addon~input.rs-input,
.rs-input-group.rs-input-group-inside .rs-input-group-btn~.rs-auto-complete>input.rs-input,
.rs-input-group.rs-input-group-inside .rs-input-group-btn~.rs-form-control-wrapper>input.rs-input,
.rs-input-group.rs-input-group-inside .rs-input-group-btn~input.rs-input {
     padding-left: 0;
     padding-right: 12px
}

.rs-input-group.rs-input-group-inside .rs-auto-complete~.rs-input-group-addon,
.rs-input-group.rs-input-group-inside .rs-form-control-wrapper~.rs-input-group-addon,
.rs-input-group.rs-input-group-inside .rs-form-control-wrapper~.rs-input-group-btn,
.rs-input-group.rs-input-group-inside input.rs-input~.rs-input-group-btn {
     left: inherit;
     right: 0
}

.rs-input-group.rs-input-group-inside .rs-auto-complete~.rs-input-group-btn,
.rs-input-group.rs-input-group-inside .rs-form-control-wrapper~.rs-input-group-btn {
     left: inherit;
     right: 1px
}

.rs-input-group-disabled {
     background-color: #f7f7fa;
     background-color: var(--rs-input-disabled-bg);
     color: #c5c6c7;
     color: var(--rs-text-disabled);
     cursor: not-allowed
}

.rs-input-group-disabled .rs-input,
.rs-input-group-disabled .rs-input-group-addon,
.rs-input-group-disabled .rs-input-group-btn {
     color: inherit
}

.rs-input-group-lg>.rs-input {
     font-size: 16px;
     line-height: 1.375;
     padding: 9px 11px
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
     .rs-input-group-lg>.rs-input {
          height: 42px
     }
}

textarea.rs-input-group-lg>.rs-input {
     height: auto
}

.rs-input-group-lg.rs-input-group-inside>.rs-input {
     padding-right: 0
}

.rs-input-group-lg.rs-input-group>.rs-input,
.rs-input-group-lg.rs-input-group>.rs-input-group-addon {
     height: 40px
}

.rs-input-group-lg.rs-input-group>.rs-input-group-addon>.rs-icon {
     font-size: inherit
}

.rs-input-group-lg.rs-input-group:not(.rs-input-group-inside)>.rs-input-group-addon {
     padding-bottom: 8px;
     padding-top: 8px
}

.rs-input-group-md>.rs-input {
     font-size: 14px;
     line-height: 1.42857143;
     padding: 7px 11px
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
     .rs-input-group-md>.rs-input {
          height: 36px
     }
}

textarea.rs-input-group-md>.rs-input {
     height: auto
}

.rs-input-group-md.rs-input-group-inside>.rs-input {
     padding-right: 0
}

.rs-input-group-md.rs-input-group>.rs-input,
.rs-input-group-md.rs-input-group>.rs-input-group-addon {
     height: 34px
}

.rs-input-group-md.rs-input-group>.rs-input-group-addon>.rs-icon {
     font-size: inherit
}

.rs-input-group-md.rs-input-group:not(.rs-input-group-inside)>.rs-input-group-addon {
     padding-bottom: 6px;
     padding-top: 6px
}

.rs-input-group-sm>.rs-input {
     font-size: 14px;
     line-height: 1.42857143;
     padding: 4px 11px
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
     .rs-input-group-sm>.rs-input {
          height: 30px
     }
}

textarea.rs-input-group-sm>.rs-input {
     height: auto
}

.rs-input-group-sm.rs-input-group-inside>.rs-input {
     padding-right: 0
}

.rs-input-group-sm.rs-input-group>.rs-input,
.rs-input-group-sm.rs-input-group>.rs-input-group-addon {
     height: 28px
}

.rs-input-group-sm.rs-input-group>.rs-input-group-addon>.rs-icon {
     font-size: inherit
}

.rs-input-group-sm.rs-input-group:not(.rs-input-group-inside)>.rs-input-group-addon {
     padding-bottom: 3px;
     padding-top: 3px
}

.rs-input-group-xs>.rs-input {
     font-size: 12px;
     line-height: 1.66666667;
     padding: 1px 11px
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
     .rs-input-group-xs>.rs-input {
          height: 24px
     }
}

textarea.rs-input-group-xs>.rs-input {
     height: auto
}

.rs-input-group-xs.rs-input-group-inside>.rs-input {
     padding-right: 0
}

.rs-input-group-xs.rs-input-group>.rs-input,
.rs-input-group-xs.rs-input-group>.rs-input-group-addon {
     height: 22px
}

.rs-input-group-xs.rs-input-group>.rs-input-group-addon>.rs-icon {
     font-size: inherit
}

.rs-input-group.rs-input-group-lg>.rs-input-group-addon {
     font-size: 16px;
     line-height: 1.375;
     min-width: 44px;
     padding: 9px 15px
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
     .rs-input-group.rs-input-group-lg>.rs-input-group-addon {
          height: 42px
     }
}

textarea.rs-input-group.rs-input-group-lg>.rs-input-group-addon {
     height: auto
}

.rs-input-group.rs-input-group-md>.rs-input-group-addon {
     font-size: 14px;
     line-height: 1.42857143;
     min-width: 36px;
     padding: 7px 11px
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
     .rs-input-group.rs-input-group-md>.rs-input-group-addon {
          height: 36px
     }
}

textarea.rs-input-group.rs-input-group-md>.rs-input-group-addon {
     height: auto
}

.rs-input-group.rs-input-group-sm>.rs-input-group-addon {
     font-size: 14px;
     line-height: 1.42857143;
     min-width: 32px;
     padding: 4px 9px
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
     .rs-input-group.rs-input-group-sm>.rs-input-group-addon {
          height: 30px
     }
}

textarea.rs-input-group.rs-input-group-sm>.rs-input-group-addon {
     height: auto
}

.rs-input-group.rs-input-group-xs>.rs-input-group-addon {
     font-size: 12px;
     line-height: 1.66666667;
     min-width: 28px;
     padding: 1px 7px
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
     .rs-input-group.rs-input-group-xs>.rs-input-group-addon {
          height: 24px
     }
}

textarea.rs-input-group.rs-input-group-xs>.rs-input-group-addon {
     height: auto
}

.rs-input-group-inside.rs-input-group-lg>.rs-input-group-btn {
     border-radius: 0 6px 6px 0;
     font-size: 16px;
     height: 40px;
     line-height: 1.375;
     padding: 9px 15px
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
     .rs-input-group-inside.rs-input-group-lg>.rs-input-group-btn {
          height: 42px
     }
}

textarea.rs-input-group-inside.rs-input-group-lg>.rs-input-group-btn {
     height: auto
}

.rs-input-group-inside.rs-input-group-lg>.rs-input-group-btn>.rs-icon {
     font-size: 16px;
     line-height: 1.375
}

.rs-input-group-inside.rs-input-group-md>.rs-input-group-btn {
     border-radius: 0 6px 6px 0;
     font-size: 14px;
     height: 34px;
     line-height: 1.42857143;
     padding: 7px 11px
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
     .rs-input-group-inside.rs-input-group-md>.rs-input-group-btn {
          height: 36px
     }
}

textarea.rs-input-group-inside.rs-input-group-md>.rs-input-group-btn {
     height: auto
}

.rs-input-group-inside.rs-input-group-md>.rs-input-group-btn>.rs-icon {
     font-size: 14px;
     line-height: 1.42857143
}

.rs-input-group-inside.rs-input-group-sm>.rs-input-group-btn {
     border-radius: 0 6px 6px 0;
     font-size: 14px;
     height: 28px;
     line-height: 1.42857143;
     padding: 4px 9px
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
     .rs-input-group-inside.rs-input-group-sm>.rs-input-group-btn {
          height: 30px
     }
}

textarea.rs-input-group-inside.rs-input-group-sm>.rs-input-group-btn {
     height: auto
}

.rs-input-group-inside.rs-input-group-sm>.rs-input-group-btn>.rs-icon {
     font-size: 12px;
     line-height: 1.66666667
}

.rs-input-group-inside.rs-input-group-xs>.rs-input-group-btn {
     border-radius: 0 6px 6px 0;
     font-size: 12px;
     height: 22px;
     line-height: 1.66666667;
     padding: 1px 7px
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
     .rs-input-group-inside.rs-input-group-xs>.rs-input-group-btn {
          height: 24px
     }
}

textarea.rs-input-group-inside.rs-input-group-xs>.rs-input-group-btn {
     height: auto
}

.rs-input-group-inside.rs-input-group-xs>.rs-input-group-btn>.rs-icon {
     font-size: 12px;
     line-height: 1.66666667
}

.rs-input-group-addon:not(:first-child):not(:last-child),
.rs-input-group-btn:not(:first-child):not(:last-child),
.rs-input-group:not(.rs-input-group-inside) .rs-input:not(:first-child):not(:last-child) {
     border-radius: 0
}

.rs-input-group-addon {
     -webkit-box-flex: 0;
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     background-color: #f7f7fa;
     background-color: var(--rs-btn-default-bg);
     border: 1px solid var(--pro-outline-border);
     border-radius: 3px;
     color: var(--rs-text-primary);
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -ms-flex: 0 0 auto;
     flex: 0 0 auto;
     font-size: 14px;
     font-weight: 400;
     line-height: 1;
     padding: 8px 12px;
     text-align: center;
     vertical-align: middle;
     white-space: nowrap
}

.rs-input-group-addon.rs-input-sm {
     border-radius: 3px;
     font-size: 12px;
     padding: 5px 10px
}

.rs-input-group-addon.rs-input-xs {
     border-radius: 3px;
     font-size: 12px;
     padding: 8px
}

.rs-input-group-addon.rs-input-lg {
     border-radius: 3px;
     font-size: 16px;
     padding: 10px 16px
}

.rs-input-group-addon input[type=checkbox],
.rs-input-group-addon input[type=radio] {
     margin-top: 0
}

.rs-input-group-btn {
     border-radius: 0;
     line-height: 1;
     position: relative;
     white-space: nowrap
}

.rs-input-group-btn>.rs-btn {
     position: relative
}

.rs-input-group-btn>.rs-btn+.rs-btn {
     margin-left: -1px
}

.rs-input-group-btn:first-child>.rs-btn,
.rs-input-group-btn:first-child>.rs-btn-group {
     border-right: none;
     margin-right: -1px
}

.rs-input-group-btn:last-child>.rs-btn,
.rs-input-group-btn:last-child>.rs-btn-group {
     margin-left: -1px
}

.rs-input-number {
     background-color: #fff;
     background-color: var(--rs-input-bg)
}

.rs-input-number-btn-group-vertical {
     -webkit-box-flex: 0;
     display: block;
     -ms-flex: 0 0 20px;
     flex: 0 0 20px;
     position: relative;
     width: 20px
}

.rs-input-number-btn-group-vertical>.rs-btn {
     border: none;
     display: block;
     float: none;
     max-width: 100%;
     position: relative;
     width: 100%
}

.rs-input-number-touchspin-down,
.rs-input-number-touchspin-up {
     border-radius: 0;
     -webkit-box-shadow: none !important;
     box-shadow: none !important;
     height: 17px;
     padding: 0;
     position: relative
}

.rs-input-number-touchspin-down>.rs-icon,
.rs-input-number-touchspin-up>.rs-icon {
     height: 17px
}

.rs-input-group-lg .rs-input-number-touchspin-down,
.rs-input-group-lg .rs-input-number-touchspin-down>.rs-icon,
.rs-input-group-lg .rs-input-number-touchspin-up,
.rs-input-group-lg .rs-input-number-touchspin-up>.rs-icon {
     height: 20px
}

.rs-input-group-sm .rs-input-number-touchspin-down,
.rs-input-group-sm .rs-input-number-touchspin-down>.rs-icon,
.rs-input-group-sm .rs-input-number-touchspin-up,
.rs-input-group-sm .rs-input-number-touchspin-up>.rs-icon {
     height: 14px
}

.rs-input-group-xs .rs-input-number-touchspin-down,
.rs-input-group-xs .rs-input-number-touchspin-down>.rs-icon,
.rs-input-group-xs .rs-input-number-touchspin-up,
.rs-input-group-xs .rs-input-number-touchspin-up>.rs-icon {
     height: 11px
}

.rs-input-number-touchspin-down>.rs-icon,
.rs-input-number-touchspin-up>.rs-icon {
     -webkit-box-align: center;
     -ms-flex-align: center;
     -webkit-box-pack: center;
     -ms-flex-pack: center;
     align-items: center;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     font-size: 12px;
     justify-content: center;
     left: 0;
     position: absolute;
     top: 0;
     width: 100%
}

.rs-input-number-btn-group-vertical:last-child .rs-input-number-touchspin-up {
     border-top-right-radius: 6px
}

.rs-input-number-btn-group-vertical:last-child .rs-input-number-touchspin-down {
     border-bottom-right-radius: 6px
}

.rs-input-number>.rs-input {
     border-bottom-left-radius: 6px !important;
     border-top-left-radius: 6px !important
}

.rs-input-number>.rs-input:disabled+.rs-input-number-btn-group-vertical .rs-input-number-touchspin-down,
.rs-input-number>.rs-input:disabled+.rs-input-number-btn-group-vertical .rs-input-number-touchspin-up {
     background-color: #f7f7fa;
     background-color: var(--rs-input-disabled-bg)
}

.rs-input-number input[type=number] {
     -webkit-appearance: textfield;
     -moz-appearance: textfield;
     appearance: textfield
}

.rs-input-number input[type=number]::-webkit-inner-spin-button {
     -webkit-appearance: none
}

.rs-picker-input {
     background-color: #fff;
     background-color: var(--rs-input-bg);
     border: 1px solid var(--pro-outline-border);
     border-radius: 3px;
     position: relative;
     -webkit-transition: border-color .15s ease-in-out;
     transition: border-color .15s ease-in-out
}

.rs-picker-input .rs-picker-toggle {
     background: transparent !important;
     border: none !important;
     cursor: text;
     height: 34px
}

.rs-picker-input .rs-picker-toggle-caret,
.rs-picker-input .rs-picker-toggle-clean {
     top: 7px
}

.rs-picker-input .rs-picker-toggle .rs-ripple-pond {
     display: none
}

.rs-picker-input:not(.rs-picker-disabled) .rs-picker-toggle {
     position: absolute !important
}

.rs-picker-input .rs-picker-toggle.rs-btn-lg {
     height: 40px
}

.rs-picker-input .rs-picker-toggle.rs-btn-md {
     height: 34px
}

.rs-picker-input .rs-picker-toggle.rs-btn-sm {
     height: 28px
}

.rs-picker-input .rs-picker-toggle.rs-btn-xs {
     height: 22px
}

.rs-picker-textbox {
     margin-right: 32px;
     min-height: 34px
}

.rs-picker-cleanable .rs-picker-textbox {
     margin-right: 44px
}

.rs-picker-search {
     border: none;
     width: 100%
}

.rs-picker-search-input {
     background: none;
     border: none;
     font-size: 14px;
     line-height: 1.42857143;
     outline: none;
     padding: 7px 0 7px 11px;
     position: relative;
     width: 100%
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
     .rs-picker-search-input {
          height: 36px
     }
}

textarea.rs-picker-search-input {
     height: auto
}

.rs-picker-tag .rs-picker-search-input {
     font-size: 14px;
     line-height: 1.42857143;
     padding: 2px 12px 2px 11px
}

.rs-picker-tag .rs-picker-search-input>input {
     background: none;
     border: none;
     outline: none;
     width: 100%
}

.rs-picker-focused .rs-picker-search-input {
     z-index: 6
}

.rs-picker-input-lg .rs-picker-search-input {
     font-size: 16px;
     line-height: 1.375;
     padding: 8px 16px
}

.rs-picker-input-lg .rs-picker-textbox {
     min-height: 40px
}

.rs-picker-input-md .rs-picker-search-input {
     font-size: 14px;
     line-height: 1.42857143;
     padding: 7px 12px
}

.rs-picker-input-sm .rs-picker-search-input {
     font-size: 14px;
     line-height: 1.42857143;
     padding: 4px 10px
}

.rs-picker-input-sm .rs-picker-textbox {
     min-height: 28px
}

.rs-picker-input-xs .rs-picker-search-input {
     font-size: 12px;
     line-height: 1.66666667;
     padding: 1px 8px
}

.rs-picker-input-xs .rs-picker-textbox {
     min-height: 22px
}


.rs-picker-select-menu-items {
     overflow-y: auto
}

.rs-picker-select-menu-items ul {
     list-style: none;
     margin: 0;
     padding: 0
}

.rs-picker-select-menu-items .rs-picker-menu-group:not(:first-child) {
     border-top: 1px solid #e5e5ea;
     border-top: 1px solid var(--pro-outline-border);
     margin-top: 6px;
     padding-top: 6px
}

.rs-picker-select-menu-items .rs-picker-menu-group-title {
     color: #272c36;
     color: var(--rs-text-heading);
     cursor: pointer;
     padding: 8px 32px 8px 12px;
     position: relative
}

.rs-picker-select-menu-items .rs-picker-menu-group-title .rs-picker-menu-group-caret {
     color: #8e8e93;
     color: var(--rs-text-secondary);
     display: inline-block;
     margin-left: 2px;
     padding: 3px;
     position: absolute;
     right: 12px;
     top: 8px
}

.rs-picker-select-menu-items .rs-picker-menu-group.folded .rs-picker-menu-group-caret {
     -webkit-transform: rotate(90deg);
     transform: rotate(90deg)
}

.rs-picker-select-menu-items .rs-picker-menu-group~[role=option]>.rs-picker-select-menu-item {
     padding-left: 26px
}

.rs-picker-select-menu-item {
     clear: both;
     color: var(--rs-text-primary);
     cursor: pointer;
     display: block;
     font-weight: 400;
     line-height: 1.42857143;
     padding: 8px 12px;
     text-decoration: none;
     width: 100%
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-focus,
.rs-picker-select-menu-item:focus,
.rs-picker-select-menu-item:hover {
     background-color: rgb(from #cce9ff rgb/50%);
     background-color: var(--pro-nav-btn-link-hover);
     color: #1675e0;
     color: var(--rs-listbox-option-hover-text)
}


.rs-picker-select-menu-item.rs-picker-select-menu-item-active {
     background-color: #f2faff;
     background-color: var(--rs-listbox-option-selected-bg);
     color: #1675e0;
     color: var(--rs-listbox-option-selected-text);
     font-weight: 700
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-disabled {
     background: none;
     color: #c5c6c7;
     color: var(--rs-listbox-option-disabled-text);
     cursor: not-allowed
}

.grouped .rs-picker-select-menu-item,
.rs-picker-select-menu-group-children .rs-picker-select-menu-item {
     padding-left: 26px
}

.rs-btn-close {
     background: transparent;
     border: none;
     cursor: pointer;
     line-height: 1;
     outline: none !important
}

.rs-btn-close .rs-icon {
     vertical-align: bottom
}

.rs-btn-close:focus,
.rs-btn-close:hover {
     color: #f44336;
     color: var(--rs-close-button-hover-color)
}

.rs-btn-close:focus svg path,
.rs-btn-close:hover svg path {
     stroke: #f44336;
     stroke: var(--rs-close-button-hover-color);
     stroke-width: 1
}

.rs-picker-toggle-wrapper {
     display: inline-block;
     max-width: 100%;
     vertical-align: middle
}

.rs-picker-toggle.rs-btn .rs-ripple-pond {
     display: none !important
}

.rs-picker-block {
     display: block
}

.rs-picker-disabled {
     opacity: .3
}

.rs-picker-toggle .rs-picker-toggle-placeholder {
     color: #8e8e93;
     color: var(--rs-text-secondary)
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
     color: #1675e0;
     color: var(--rs-picker-value)
}

.rs-picker-none {
     color: #8e8e93;
     color: var(--rs-text-secondary);
     cursor: default;
     padding: 6px 12px 12px
}

.rs-picker-countable .rs-picker-toggle-value {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex
}

.rs-picker-value-list {
     -webkit-box-flex: 0;
     -ms-flex: 0 1 auto;
     flex: 0 1 auto;
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
     .rs-picker-value-list {
          max-width: 100%
     }
}

.rs-picker-value-count {
     background-color: #3498ff;
     background-color: var(--rs-picker-count-bg);
     border-radius: 10px;
     color: #fff;
     color: var(--rs-picker-count-text);
     line-height: 20px;
     margin: 0 4px;
     padding: 0 8px
}

.rs-picker-value-separator {
     margin: 0 4px 0 0
}

.rs-picker.rs-picker-focused,
.rs-picker:not(.rs-picker-disabled):hover {
     border-color: #3498ff;
     border-color: var(--rs-input-focus-border)
}

.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
     -webkit-box-shadow: 0 0 0 3px rgb(from #3498ff rgb/25%);
     -webkit-box-shadow: var(--rs-state-focus-shadow);
     box-shadow: 0 0 0 3px rgb(from #3498ff rgb/25%);
     box-shadow: var(--rs-state-focus-shadow)
}

.rs-picker-label.rs-input-group-addon {
     color: var(--rs-text-primary);
     padding-right: 4px !important
}

.rs-picker-loader {
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     height: 14px;
     width: 14px
}

.rs-picker-error>.rs-input-group {
     border-color: #f44336 !important;
     border-color: var(--rs-state-error) !important
}

.rs-picker-error>.rs-input-group:focus-within {
     outline: 3px solid rgb(from #f44336 rgb/25%) !important;
     outline: var(--rs-state-error-outline) !important
}

.rs-picker-toggle {
     -webkit-box-align: center;
     -ms-flex-align: center;
     -webkit-box-pack: center;
     -ms-flex-pack: center;
     align-items: center;
     background-color: #f7f7fa;
     background-color: var(--rs-btn-default-bg);
     border: none;
     border: var(--rs-btn-default-border, none);
     border-radius: 3px;
     color: var(--rs-btn-default-text);
     cursor: pointer;
     display: -webkit-inline-box;
     display: -ms-inline-flexbox;
     display: inline-flex;
     font-size: 14px;
     font-weight: 400;
     justify-content: center;
     line-height: 20px;
     margin-bottom: 0;
     padding: 8px 12px;
     position: relative;
     text-align: center;
     text-decoration: none;
     -webkit-transition: color .15s ease-out, background-color .15s ease-out;
     transition: color .15s ease-out, background-color .15s ease-out;
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
     vertical-align: middle
}

.rs-theme-high-contrast .rs-picker-toggle {
     -webkit-transition: none;
     transition: none
}

.rs-btn-ghost.rs-picker-toggle {
     padding: 7px 11px
}

.rs-btn-icon.rs-picker-toggle {
     line-height: 16px;
     padding: 10px
}

.rs-btn-icon.rs-picker-toggle>.rs-icon {
     font-size: 16px
}

.rs-btn-icon-with-text.rs-picker-toggle {
     line-height: 20px
}

.rs-btn-icon-with-text.rs-picker-toggle>.rs-icon {
     height: 36px;
     padding: 10px;
     width: 36px
}

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-left {
     padding: 8px 12px 8px 48px
}

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-left>.rs-icon {
     border-right: none;
     border-right: var(--rs-btn-default-border, none);
     left: 0
}

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-right {
     padding: 8px 48px 8px 12px
}

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-right>.rs-icon {
     border-left: none;
     border-left: var(--rs-btn-default-border, none);
     right: 0
}

.rs-picker-toggle:focus-visible {
     outline: 3px solid rgb(from #3498ff rgb/25%);
     outline: 3px solid var(--rs-color-focus-ring)
}

.rs-theme-high-contrast .rs-picker-toggle:focus-visible {
     outline-offset: 2px
}

.rs-picker-toggle:focus,
.rs-picker-toggle:hover {
     background-color: #e5e5ea;
     background-color: var(--rs-btn-default-hover-bg);
     color: var(--rs-btn-default-hover-text);
     text-decoration: none
}

.rs-picker-toggle.rs-btn-active,
.rs-picker-toggle:active {
     background-color: #d9d9d9;
     background-color: var(--rs-btn-default-active-bg);
     color: #272c36;
     color: var(--rs-btn-default-active-text)
}

.rs-picker-toggle.rs-btn-disabled,
.rs-picker-toggle:disabled {
     background-color: #f7f7fa;
     background-color: var(--rs-btn-default-disabled-bg);
     color: #c5c6c7;
     color: var(--rs-btn-default-disabled-text);
     cursor: not-allowed
}

.rs-theme-high-contrast .rs-picker-toggle.rs-btn-disabled,
.rs-theme-high-contrast .rs-picker-toggle:disabled {
     opacity: .5
}

@media not all and (min-resolution:0.001dpcm) {
     .rs-picker-toggle {
          -webkit-mask-image: -webkit-radial-gradient(#fff, #000)
     }
}

.rs-picker-default .rs-picker-toggle {
     padding: 7px 11px
}

.rs-picker-toggle-textbox {
     background-color: #fff;
     background-color: var(--rs-input-bg);
     border: 1px solid #0000;
     color: var(--rs-text-primary);
     height: 100%;
     left: 0;
     outline: none;
     padding-left: 10px;
     padding-right: 32px;
     position: absolute;
     top: 0;
     width: 100%
}

.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-textbox {
     padding-left: 14px
}

.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-textbox {
     padding-left: 8px
}

.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-textbox {
     padding-left: 6px
}

.rs-picker-toggle-read-only {
     opacity: 0
}

.rs-picker-default .rs-picker-toggle {
     -webkit-transition: border-color .15s ease-in-out;
     transition: border-color .15s ease-in-out
}

.rs-theme-high-contrast .rs-picker-default .rs-picker-toggle {
     -webkit-transition: none;
     transition: none
}

.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
     border-color: #3498ff;
     border-color: var(--rs-input-focus-border)
}

.rs-picker-subtle .rs-picker-toggle {
     background-color: transparent;
     border: none;
     color: var(--rs-btn-subtle-text);
     -webkit-transition: none;
     transition: none
}

.rs-picker-subtle .rs-picker-toggle:focus,
.rs-picker-subtle .rs-picker-toggle:hover {
     background-color: #e5e5ea;
     background-color: var(--rs-btn-subtle-hover-bg);
     color: var(--rs-btn-subtle-hover-text)
}

.rs-picker-subtle .rs-picker-toggle.rs-btn-active,
.rs-picker-subtle .rs-picker-toggle:active {
     background-color: #e5e5ea;
     background-color: var(--rs-btn-subtle-active-bg);
     color: #272c36;
     color: var(--rs-btn-subtle-active-text)
}

.rs-picker-subtle .rs-picker-toggle.rs-btn-disabled,
.rs-picker-subtle .rs-picker-toggle:disabled {
     background: none;
     color: #c5c6c7;
     color: var(--rs-btn-subtle-disabled-text)
}

.rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled,
.rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle:disabled {
     opacity: .5
}

.rs-picker-subtle .rs-picker-toggle-active {
     background-color: #e5e5ea;
     background-color: var(--rs-btn-subtle-hover-bg);
     color: var(--rs-btn-subtle-hover-text)
}

.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn,
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
     padding-right: 32px
}

.rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean {
     right: 12px;
     top: 8px
}

.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean {
     top: 7px
}

.rs-picker-date .rs-picker-toggle.rs-btn,
.rs-picker-daterange .rs-picker-toggle.rs-btn {
     padding-right: 36px
}

.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean {
     right: 12px;
     top: 8px
}

.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-indicator .rs-picker-clean {
     top: 7px
}

.rs-picker-default .rs-picker-toggle.rs-btn {
     padding-bottom: 7px;
     padding-top: 7px
}

.rs-picker-toggle.rs-btn-lg {
     font-size: 16px;
     line-height: 22px;
     padding: 10px 16px
}

.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-lg,
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-lg {
     padding-right: 36px
}

.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-clean {
     right: 16px;
     top: 10px
}

.rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-clean {
     top: 9px
}

.rs-picker-default .rs-picker-toggle.rs-btn-lg {
     padding-left: 15px
}

.rs-picker-date .rs-picker-toggle.rs-btn-lg,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg {
     padding-right: 42px
}

.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-clean {
     right: 16px;
     top: 10px
}

.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-clean {
     top: 9px
}

.rs-picker-default .rs-picker-toggle.rs-btn-lg {
     padding-bottom: 9px;
     padding-top: 9px
}

.rs-btn-ghost.rs-picker-toggle.rs-btn-lg {
     padding: 9px 15px
}

.rs-btn-icon.rs-picker-toggle.rs-btn-lg {
     line-height: 20px;
     padding: 11px
}

.rs-btn-icon.rs-picker-toggle.rs-btn-lg>.rs-icon {
     font-size: 20px
}

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg {
     line-height: 22px
}

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg>.rs-icon {
     height: 42px;
     padding: 11px;
     width: 42px
}

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-left {
     padding: 10px 16px 10px 58px
}

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-left>.rs-icon {
     border-right: none;
     border-right: var(--rs-btn-default-border, none);
     left: 0
}

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-right {
     padding: 10px 58px 10px 16px
}

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-right>.rs-icon {
     border-left: none;
     border-left: var(--rs-btn-default-border, none);
     right: 0
}

.rs-picker-toggle.rs-btn-lg .rs-picker-value-count {
     line-height: 22px
}

.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-md,
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-md {
     padding-right: 32px
}

.rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-clean {
     right: 12px;
     top: 8px
}

.rs-picker-default .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-default .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-clean {
     top: 7px
}

.rs-picker-default .rs-picker-toggle.rs-btn-md {
     padding-left: 11px
}

.rs-picker-date .rs-picker-toggle.rs-btn-md,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md {
     padding-right: 36px
}

.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-clean {
     right: 12px;
     top: 8px
}

.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-indicator .rs-picker-clean {
     top: 7px
}

.rs-picker-default .rs-picker-toggle.rs-btn-md {
     padding-bottom: 7px;
     padding-top: 7px
}

.rs-picker-toggle.rs-btn-sm {
     font-size: 14px;
     line-height: 20px;
     padding: 5px 10px
}

.rs-btn-ghost.rs-picker-toggle.rs-btn-sm {
     padding: 4px 9px
}

.rs-btn-icon.rs-picker-toggle.rs-btn-sm {
     line-height: 16px;
     padding: 7px
}

.rs-btn-icon.rs-picker-toggle.rs-btn-sm>.rs-icon {
     font-size: 16px
}

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm {
     line-height: 20px
}

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm>.rs-icon {
     height: 30px;
     padding: 7px;
     width: 30px
}

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-left {
     padding: 5px 10px 5px 40px
}

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-left>.rs-icon {
     border-right: none;
     border-right: var(--rs-btn-default-border, none);
     left: 0
}

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-right {
     padding: 5px 40px 5px 10px
}

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-right>.rs-icon {
     border-left: none;
     border-left: var(--rs-btn-default-border, none);
     right: 0
}

.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-sm,
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-sm {
     padding-right: 30px
}

.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-clean {
     right: 10px;
     top: 5px
}

.rs-picker-default .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-default .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-clean {
     top: 4px
}

.rs-picker-default .rs-picker-toggle.rs-btn-sm {
     padding-left: 9px
}

.rs-picker-date .rs-picker-toggle.rs-btn-sm,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm {
     padding-right: 34px
}

.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-clean {
     right: 10px;
     top: 4px
}

.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-indicator .rs-picker-clean {
     top: 3px
}

.rs-picker-default .rs-picker-toggle.rs-btn-sm {
     padding-bottom: 4px;
     padding-top: 4px
}

.rs-picker-toggle.rs-btn-xs {
     font-size: 12px;
     line-height: 20px;
     padding: 2px 8px
}

.rs-btn-ghost.rs-picker-toggle.rs-btn-xs {
     padding: 1px 7px
}

.rs-btn-icon.rs-picker-toggle.rs-btn-xs {
     line-height: 12px;
     padding: 6px
}

.rs-btn-icon.rs-picker-toggle.rs-btn-xs>.rs-icon {
     font-size: 12px
}

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs {
     line-height: 20px
}

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs>.rs-icon {
     height: 24px;
     padding: 6px;
     width: 24px
}

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-left {
     padding: 2px 8px 2px 32px
}

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-left>.rs-icon {
     border-right: none;
     border-right: var(--rs-btn-default-border, none);
     left: 0
}

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-right {
     padding: 2px 32px 2px 8px
}

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-right>.rs-icon {
     border-left: none;
     border-left: var(--rs-btn-default-border, none);
     right: 0
}

.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-xs,
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-xs {
     padding-right: 28px
}

.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-clean {
     right: 8px;
     top: 2px
}

.rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-clean {
     top: 1px
}

.rs-picker-default .rs-picker-toggle.rs-btn-xs {
     padding-left: 7px
}

.rs-picker-date .rs-picker-toggle.rs-btn-xs,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs {
     padding-right: 30px
}

.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-clean {
     right: 8px;
     top: 2px
}

.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-indicator .rs-picker-clean {
     top: 1px
}

.rs-picker-default .rs-picker-toggle.rs-btn-xs {
     padding-bottom: 1px;
     padding-top: 1px
}

.rs-picker-toggle {
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
     width: 100%
}

.rs-picker-default .rs-picker-toggle {
     border: 1px solid var(--pro-outline-border);
     color: var(--rs-text-primary);
     display: inline-block;
     padding-right: 32px;
     position: relative;
     z-index: 5
}

.rs-picker-disabled .rs-picker-toggle {
     cursor: not-allowed
}

.rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):focus,
.rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):hover {
     border-color: #3498ff;
     border-color: var(--rs-input-focus-border)
}

.rs-picker-subtle .rs-picker-toggle {
     display: inline-block;
     padding-right: 32px;
     position: relative;
     z-index: 5
}

.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle {
     cursor: not-allowed
}

.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:active,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:focus,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:hover {
     background: none
}

.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:active:after,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:focus:after,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:hover:after {
     display: none
}

.rs-picker-toggle.rs-btn {
     text-align: left;
     width: 100%
}

.rs-picker-toggle-label {
     color: var(--rs-text-primary)
}

.rs-picker-toggle-label:after {
     content: ":";
     margin: 0 4px 0 2px
}

.rs-picker-toggle-value {
     display: block;
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
     width: 100%
}

.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
     padding-right: 44px
}

.rs-picker-toggle-indicator .rs-picker-clean {
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     background: inherit;
     color: #8e8e93;
     color: var(--rs-text-secondary);
     cursor: pointer;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     font-size: 12px;
     height: 20px;
     margin-left: 2px;
     position: absolute;
     right: 30px;
     top: 8px;
     -webkit-transition: color .2s linear;
     transition: color .2s linear
}

.rs-picker-toggle-indicator .rs-picker-clean.rs-btn-close {
     padding: 4px 0
}

.rs-picker-date .rs-picker-toggle-indicator .rs-picker-clean,
.rs-picker-daterange .rs-picker-toggle-indicator .rs-picker-clean {
     right: 38px
}

.rs-picker-toggle-indicator .rs-picker-caret-icon {
     color: #8e8e93;
     color: var(--rs-text-secondary)
}

.rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-toggle-indicator .rs-picker-loader {
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     font-size: 12px;
     height: 20px;
     margin-left: 2px;
     position: absolute;
     right: 12px;
     top: 8px
}

.rs-picker-popup {
     -webkit-box-orient: vertical;
     -webkit-box-direction: normal;
     background-color: var(--pro-light);
     border-radius: 3px;
     -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, .12), 0 0 10px rgba(0, 0, 0, .06);
     box-shadow: 0 4px 4px rgba(0, 0, 0, .12), 0 0 10px rgba(0, 0, 0, .06);
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -ms-flex-direction: column;
     flex-direction: column;
     overflow: hidden;
     padding: 6px 0;
     position: absolute;
     text-align: left;
     -webkit-transition: none;
     transition: none;
     z-index: 7
}

.rs-theme-high-contrast .rs-picker-popup {
     border: 1px solid var(--pro-outline-border)
}

.rs-drawer-open .rs-picker-popup,
.rs-modal-open .rs-picker-popup {
     z-index: 1055
}

.rs-picker-popup.rs-picker-inline {
     -webkit-box-shadow: none;
     box-shadow: none;
     position: relative
}

.rs-picker-popup .rs-search-box {
     padding: 6px 12px
}

.rs-picker-default .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-btn,
.rs-picker-input .rs-picker-toggle {
     background-color: #fff !important;
     background-color: var(--rs-input-bg) !important
}

.rs-picker-default.rs-picker-disabled .rs-btn,
.rs-picker-default.rs-picker-disabled .rs-picker-toggle,
.rs-picker-input.rs-picker-disabled .rs-btn,
.rs-picker-input.rs-picker-disabled .rs-picker-toggle {
     background-color: #f7f7fa !important;
     background-color: var(--rs-input-disabled-bg) !important
}



/*# sourceMappingURL=rsuite.min.css.map */

.rs-picker-toolbar-right {
     display: flex;
     justify-content: flex-end;
}