.root {
    :global {
        .pro-btn-group {
            display: flex;
            flex-wrap: wrap;

            .pro-btn.pro-btn-outline {
                width: max-content;
                margin-bottom: 5px;
                margin-right: 5px;
            }
        }

    }

}

.call_history_modal {
    width: 320px;
    position: absolute;
    left: 50%;

    .call_history_modal_body {
        position: relative;
        width: 100%;
    }
}