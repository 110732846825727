.root{
    width: 100%;
    max-width: 100%;
    display: flex;
    height: 100%;
    position: relative;
    .root_inner{
        width: 100%;
        padding-left: 0.9625rem;
        padding-right: 0.8625rem;
        padding-top: 0.7rem;
        padding-bottom: 1.5625rem;
        transition: all .3s;
        :global{
            .pro-btn{
                >span:first-child{
                    margin-left: 0;
                }
                
                >span:last-child{
                    padding-left: 2px;
                }
            }
        }
    }
}


.expandButton {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 8px;
    right: calc(100% - 12px);
    padding: 2px;
    transition: transform 0.25s ease-out;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
        transition: .3s all ease-in-out;
    }
  }

// :global(.right-drawer){
//     position: fixed;
//     right: 0;
//     top: 0;
//     transition: .3s transform ease;
// }
.root_inner {
    overflow: hidden;
    ul{
        overflow: hidden;
        li{
            transition: .3s width ease-in-out;
            height: 34px;
            
            span{
               transition: .3s all ease;
            }
             button{
                justify-content: flex-start !important;
                overflow: hidden;
                span{
                    display: block;
                    &:first-child{
                        margin-left: auto;
                    }
                    
                    &:last-child{
                        margin-right: auto;
                    }
                }
             }
        }
    }
    h6{
        transition: .3s all ease;
        white-space: pre;
    }
}
// :global(.close-right-drawer){
//     .root_inner{
//         ul li{
//             width: 34px;
//             padding: 0 !important;
//             height: 34px;
//             button{
//                 padding: 0px !important;
//                 height: 100%;
//                 span {
//                     &:first-child{
//                         transform: translateX(7px);
//                     }
//                     & + span{
//                         display: block;
//                         opacity: 0;
//                     }
//                 }
//             }
//         }
//         h6{
//             opacity: 0;
//         }
//     }
    
// }

// :global(.close-right-drawer){
//     .expandButton{
//         // width: 35px;
//         span{
//             transform: scaleX(-1);
//         }
//     }
// }
 

:global(.close-right-drawer){
    .root_inner{
        padding-left: 1.2625rem;
        padding-right: 1.3625rem;
        button{
            padding-left: 10px;
        }
        h6{
            opacity: 0;
        }
        
    }
}
  