.FilterList {
    border-style: groove;
    width: 600px;
    display: flex;
    /* justify-content: space-around; */
    justify-content: space-between
}

.group {
    >div {
        display: flex;
        align-items: center;

        :global(.pro-input) {
            border-radius: 0px;
        }

        &:first-child {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;

            &~div {
                margin-left: -1px;
            }
        }

        &:last-child {
            :global(.pro-input) {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }
        }


    }

    .input_box {
        width: 375px;
    }
}