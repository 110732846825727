// .root {
//      padding: 20px;

//      .img_wrap {
//           position: relative;
//           width: 100%;
//           max-width: 80px;

//           figure {
//                overflow: hidden;
//                border-radius: 5px;
//           }
//      }

//      .head_wrap {
//           align-items: center;

//           .call_icon {
//                width: 35px;
//                height: 35px;
//                display: flex;
//                justify-content: center;
//                align-items: center;

//                .icon {
//                     color: var(--pro-success);
//                     animation: ring 1s infinite;
//                     display: inline-block;
//                     font-size: 35px !important;
//                }

//                @keyframes ring {
//                     0% {
//                          transform: scale(0.8);
//                     }

//                     50% {
//                          transform: scale(1);
//                     }

//                     100% {
//                          transform: scale(0.8);
//                     }
//                }
//           }
//      }

//      .title_wrap {
//           flex: 1;
//           padding-left: 16px;
//      }
// }

// .details_wrap {
//      padding-top: 14px;
//      margin-top: 14px;
//      border-top: 1px solid var(--pro-outline-border);

//      .inner_table {
//           table tr td:first-child {
//                width: 30%;
//           }
//      }
// }






//second

.root {
     position: relative;
     padding: 20px 25px;
     background-color: #076f06;
     color: #ffffff;

     .icon {
          cursor: pointer;
          transition: all 0.25s;
          will-change: transform;

          &:hover {
               transform: scale(1.2);
          }
     }

     .img_wrap {
          position: relative;
          width: 100%;
          max-width: 170px;
          margin: auto;

          figure {
               border-radius: 50%;
               overflow: hidden;

               img {
                    object-fit: cover;
               }
          }
     }

     .title_wrap {

          padding-top: 20px;
          display: flex;
          align-items: center;

          .name {
               margin-bottom: 5px;
          }

          .link {
               cursor: pointer;
               transition: opacity 0.3s;
               display: inline-block;

               &:hover {
                    opacity: 0.5;
               }
          }

          .call_icon {
               width: 35px;
               height: 35px;
               display: flex;
               justify-content: center;
               align-items: center;
               color: #ffffff;

               .icon {
                    animation: ring 1s infinite;
                    display: inline-block;
                    font-size: 35px !important;
               }

               @keyframes ring {
                    0% {
                         transform: scale(0.8);
                    }

                    50% {
                         transform: scale(1);
                    }

                    100% {
                         transform: scale(0.8);
                    }
               }
          }
     }
}