.main_wrapper {
  width: 100%;
  position: relative;
  padding-right: calc(var(--pro-gutter-x) * 0.5);
  padding-left: calc(var(--pro-gutter-x) * 0.5);
}

.left_sidebar {
  width: 100%;
  max-width: 24.063rem;
  height: calc(100vh - 92px);
  position: sticky;
  top: 92px;
  overflow: hidden;
  border-right: 1px solid var(--pro-outline-border);

  &_sticky {
    position: relative;

    &_top {
      // position: sticky;
      // top: 0;
      background-color: var(--pro-light);
      z-index: 99;
      padding-top: 24px;
    }

    &_scrollWrap {
      padding-bottom: 20px;
    }
  }

  .profile_layout_action {
    ul {
      padding: 0;

      li {
        display: flex;
        justify-content: center !important;
      }
    }
  }
}

:global {
  .pt-custom {
    position: sticky;
    top: 0;
    z-index: 999;
  }
}

.main_container {
  width: 100%;
  max-width: calc(100% - 37.063rem);
  transition: 0.3s max-width ease-in-out;

  &_inner {
    width: 100%;
  }

  :global {
    .nav {
      padding-inline: 1.25rem;
      padding-top: 0.563rem;
      overflow: auto;
      flex-wrap: nowrap;
      overflow-y: hidden;
      position: sticky;
      top: 92px;
      background-color: var(--pro-light);
      z-index: 9;

      .nav-link {
        white-space: pre;

        .pro-btn-link {
          --pro-button-hover-bg: var(--pro-primary-hover-bg);
          transition: none;

          &.active {
            color: var(--pro-primary);
            border-top-left-radius: 6px;
            border-top-right-radius: 7px;
            border: none;
            box-shadow: 0 0 0 1px var(--pro-outline-border);

            &::after {
              background-color: var(--pro-primary);
            }
          }

          &:not(.active):active {
            background-color: var(--pro-button-hover-bg);
          }
        }
      }
    }
  }
}

.right_sidebar {
  width: 100%;
  max-width: 13rem;
  height: calc(100vh - 92px);
  position: sticky;
  top: 92px;
  z-index: 9;
  border-left: 1px solid var(--pro-outline-border);
  transition: 0.3s max-width ease-in-out;
}

:global(.close-right-drawer) {
  .right_sidebar {
    max-width: 75px;
  }

  .main_container {
    max-width: calc(100% - 28.7884rem);
  }
}

//fixng profile page pagination

.main_wrapper {
  :global(.pro-pagination-wrapper) {
    z-index: 2;
    right: 207px;
    width: calc(100% - 592px);
  }
}

:global(.close-right-drawer) {
  .main_wrapper {
    :global(.pro-pagination-wrapper) {
      right: 76px;
      width: calc(100% - 461px);
    }
  }
}

.hide {
  display: none;
}


.remove_reason_wrap {
  border-top: 1px solid var(--pro-outline-border);

  p {
    font-weight: 500;

    &+p {
      margin-top: 6px;
    }
  }

  .delete_reason {
    color: #ff1200;
  }

  .hide_reason {
    color: #e3aa16;
  }
}

.delete_btn_wrap {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 10px;
  background-color: white;
  z-index: 2;
  width: 375px;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
}

.btn_space {
  height: 50px;
}

.delete_modal {
  --pro-modal-width: 800px;
}