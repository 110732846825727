.root {
  width: 100%;
  position: relative;
  height: 100%;
  & ~ .root {
    padding-left: 1rem;
  }
  table {
    width: 100%;
    position: relative;
    height: 100%;
    tr {
      width: 100%;
      position: relative;
      td {
        border: 1px solid var(--pro-dark);
        min-height: 50px;
        min-width: 50px;
        text-align: center;
        padding: 0.782rem;
        position: relative;
        @media (max-width: 1366px) {
          min-height: 40px;
          min-width: 40px;
        }
      }
      &:first-child {
        td {
          min-height: 50px;
        }
      }
      &:last-child {
        td {
          min-height: 50px;
        }
      }
    }
  }
}

.number {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--pro-outline-border);
  display: block;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  position: absolute;
  top: 10px;
  left: 10px;
}
